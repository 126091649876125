// src/Components/User/Login/SignUpContext.jsx
import React, { createContext, useState } from "react";

export const SignUpContext = createContext();

export const SignUpProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    role: "Admin",
    agreedToTerms: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  return (
    <SignUpContext.Provider value={{ formData, handleChange, setFormData }}>
      {children}
    </SignUpContext.Provider>
  );
};
