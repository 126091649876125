import React, { useEffect, useState } from "react";
import stye from "../Css/SuperAdminProfile.module.css";
import { Get } from "../../../Helper/ApiServices";
import { useCustomAlert } from "../../../Helper/Alerts";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BackButton, openTelegramLink, UserDetails } from "../../../Helper/TgMiniAppFunctions";
import themeBg from "../../../assets/images/theme-bg-dark.png";
import Avatar from "react-avatar";
import { SplashScreen } from "../../SplashScreen/Components/SplashScreen";
import { GetDefaultSupportGroupByBotId, GetTgUserByUserId, InviteLink } from "../../../Helper/UrlConstant";
import { decodeJwt } from "../../../Helper/Decoder";
import Cookies from "js-cookie";
import { decrypt, encrypt } from "../../../Helper/EncryptionHelper";

const SuperAdminProfile = () => {
  // ***************Hooks Starts here*****************
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const user = UserDetails();
  const { t } = useTranslation();
  const [userDetails, setUserDetails] = useState();
  const [botDetail, setBotDetail] = useState();
  const [supportGroupDetails, setSupportGroupDetails] = useState();
  const [adminDetails, setAdminDetails] = useState({
    Email: "",
    Username: "",
    Role: "",
    Id: "",
  });
  const showAlert = useCustomAlert();

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);
      await ValidateAdmins();
      await profileDetails();
      const cleanup = BackButton(() => {
        window.history.back();
      });
      setIsLoading(false);
      return cleanup;
    };
    initialize();
  }, []);
  // ***************Hooks Ends here**************

  // ****************Api Calling Function***************
  const profileDetails = async () => {
    const param = {
      TGUserId:
        user != null && user != undefined ? await encrypt(String(user.id)) : "",
      BotId: await encrypt(localStorage.getItem("BotId")),
    };
    const response = await Get(GetTgUserByUserId, param);
    if (response && response.statusCode === 200 && response.data) {
      const decryptedUserDetails = {
        ...response.data,
        tgUserID: await decrypt(response.data.tgUserID), // Decrypt TgUserID
        ipAddress: await decrypt(response.data.ipAddress), // Decrypt IPAddress
        botId: await decrypt(response.data.botId), // Decrypt IPAddress
      };

      // setUserDetails(response.data);
      setUserDetails(decryptedUserDetails);
    } else if (response && response.statusCode !== 200) {
      showAlert("", t("Something Went Wrong"), "error");
    }
  };

  function Navigation(path) {
    navigate(path);
  }

  const ValidateAdmins = async () => {
    const token = Cookies.get("qEWbpONT");
    if (token !== null && token !== undefined) {
      const decodedtoken = decodeJwt(token);
      if (decodedtoken !== null && decodedtoken !== undefined) {
        setAdminDetails({
          Email: decodedtoken.email,
          Username: decodedtoken.unique_name,
          Role: decodedtoken[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
          ],
          Id: decodedtoken.UniqueId,
        });
      }
    }
  };

  // handle Navigation to settings page
  const handleNavigatetoSettings = () => {
    navigate("/Settings", {
      state: {
        adminDetails: adminDetails,
        adminOtherDetails: userDetails,
      },
    });
  };

  const handleNavigatetoProfileManagement = () => {
    navigate("/ProfileManagement", {
      state: {
        adminDetails: adminDetails,
        adminOtherDetails: userDetails,
      },
    });
  };

  const fetchSupportGroupDetails = async () => {
    const response = await Get(GetDefaultSupportGroupByBotId, {
      TGUserId:
        user != null && user != undefined ? await encrypt(String(user.id)) : "",
      BotId: await encrypt(localStorage.getItem("BotId")),
    });
    if (response && response.statusCode === 200 && response.data) {
      setSupportGroupDetails(response.data);
      return response.data;
    } else if (response && response.statusCode !== 200) {
      showAlert("", t("Something Went Wrong"), "error");
    }
  };
  async function CreateInviteLink(ChatId) {
    try {
      const response = await Get(InviteLink, {
        ChatId: await encrypt(ChatId),
        UserId:
          user != null && user != undefined
            ? await encrypt(String(user.id))
            : "",
        BotId: await encrypt(localStorage.getItem("BotId")),
      });
      if (response && response.statusCode === 200 && response.data) {
        return response.data || [];
      } else if (response && response.statusCode !== 200) {
        showAlert("", t("Something Went Wrong"), "error");
      } else {
        console.error("Failed to Create link");
        return false;
      }
    } catch (error) {
      console.error("Error While Creating link:", error);
      return false;
    }
  }

  const handleSupportGroupClick = async () => {
    const supportGroupDetails = await fetchSupportGroupDetails();
    if (supportGroupDetails !== null && supportGroupDetails !== undefined) {
      if (
        supportGroupDetails.groupId !== null &&
        supportGroupDetails.groupId !== undefined
      ) {
        const decryptedGroupId = await decrypt(supportGroupDetails.groupId);

        var response = await CreateInviteLink(decryptedGroupId);
        if (
          response.result.invite_link !== null &&
          response.result.invite_link !== undefined
        ) {
          openTelegramLink(response.result.invite_link);
        }
      }
    }
  };
  return (
    <div>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <div id="main-content" className="main-content">
          <div
            className="bg-image"
            style={{ backgroundImage: `url(${themeBg})` }}
          ></div>
          <div className="super-admin-profile">
            <div className="container-fluid">
              <div className="header-toolbar d-flex align-items-center">
                <div className="toolbar-center text-center flex-grow-1"></div>
                {adminDetails &&
                  (adminDetails.Role === "SuperAdmin" ||
                    adminDetails.Role === "Admin") && (
                    <div className="user-setting">
                      <a
                        className="setting-btn"
                        onClick={() => handleNavigatetoSettings()}
                      >
                        <svg
                          width="24"
                          height="27"
                          viewBox="0 0 24 27"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20.8067 8.60251L20.1842 7.40928C19.6577 6.39962 18.4907 6.05132 17.5755 6.63065V6.63065C17.1399 6.91415 16.6201 6.99459 16.1307 6.85421C15.6413 6.71384 15.2226 6.36418 14.9668 5.88235C14.8023 5.57608 14.7139 5.22725 14.7105 4.87113V4.87113C14.7254 4.30018 14.5304 3.7469 14.17 3.33733C13.8096 2.92776 13.3145 2.69679 12.7975 2.69702H11.5435C11.037 2.69702 10.5513 2.92 10.194 3.31664C9.83669 3.71328 9.63717 4.25087 9.63961 4.81046V4.81046C9.6246 5.96581 8.77248 6.89368 7.72657 6.89356C7.40421 6.88986 7.08846 6.79219 6.81123 6.61042V6.61042C5.89606 6.03109 4.72911 6.3794 4.20254 7.38906L3.53435 8.60251C3.00841 9.61091 3.3194 10.8993 4.23 11.4845V11.4845C4.8219 11.862 5.18653 12.5597 5.18653 13.3148C5.18653 14.0698 4.8219 14.7675 4.23 15.1451V15.1451C3.32056 15.7263 3.00923 17.0116 3.53435 18.0169V18.0169L4.16593 19.2203C4.41265 19.7121 4.8266 20.075 5.31619 20.2287C5.80578 20.3824 6.33064 20.3143 6.77462 20.0393V20.0393C7.21108 19.758 7.73119 19.6809 8.21934 19.8252C8.70749 19.9695 9.12324 20.3233 9.37416 20.8079C9.5387 21.1141 9.62711 21.463 9.63046 21.8191V21.8191C9.63046 22.9863 10.487 23.9325 11.5435 23.9325H12.7975C13.8505 23.9325 14.7055 22.9925 14.7105 21.8292V21.8292C14.7081 21.2679 14.9089 20.7287 15.2682 20.3318C15.6275 19.9348 16.1155 19.7131 16.6236 19.7158C16.9452 19.7253 17.2596 19.8225 17.5389 19.9989V19.9989C18.4517 20.5799 19.6179 20.2364 20.1476 19.2304V19.2304L20.8067 18.0169C21.0618 17.5332 21.1318 16.9571 21.0012 16.4162C20.8706 15.8752 20.5502 15.4141 20.111 15.135V15.135C19.6718 14.8558 19.3514 14.3947 19.2208 13.8538C19.0902 13.3128 19.1603 12.7367 19.4154 12.253C19.5812 11.933 19.8214 11.6677 20.111 11.4845V11.4845C21.0161 10.8996 21.3264 9.61875 20.8067 8.61262V8.61262V8.60251Z"
                            stroke="#34353C"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <ellipse
                            cx="12.1751"
                            cy="13.3149"
                            rx="2.63616"
                            ry="2.9123"
                            stroke="#34353C"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </a>
                    </div>
                  )}
              </div>

              <div className={`${stye["profile-top"]}`}>
                <div className="admin-profile-box text-center m-auto">
                  <div className={`${stye["profile-img"]}`}>
                    {userDetails && userDetails.userProfilePhoto ? (
                      <img src={userDetails.userProfilePhoto} alt="" />
                    ) : (
                      <Avatar
                        name={userDetails?.firstName}
                        round="100%"
                        textSizeRatio={2.5}
                        color="black"
                      />
                    )}
                  </div>
                  <div className={`${stye["profile-details"]}`}>
                    <h3>
                      {userDetails?.firstName} {userDetails?.LastName}
                    </h3>
                    <p>{userDetails?.userName}</p>
                  </div>
                </div>
              </div>

              <div className="common-btn">
                <ul className="d-flex gap-2 justify-content-center ">
                  <li className="col-6">
                    <a
                      className="btn btn-primary w-100"
                      onClick={() => Navigation("/ParticipatedList")}
                    >
                      {t("Participated")}
                    </a>
                  </li>
                  <li className="col-6">
                    <a
                      className="btn btn-primary w-100"
                      onClick={() => Navigation("/CreatedList")}
                    >
                      {t("Created")}
                    </a>
                  </li>
                </ul>
              </div>

              <div className={`${stye["admin-menu-sec"]} ${stye["pb-100"]}`}>
                <ul>
                  {adminDetails && adminDetails.Role === "SuperAdmin" && (
                    <li onClick={() => Navigation("/SignUp")}>
                      <a className="d-flex justify-content-between">
                        <span className="text"> {t("Register Users")}</span>
                        <span className="icons">
                          <i className="bi bi-chevron-right"></i>
                        </span>
                      </a>
                    </li>
                  )}

                  <li onClick={() => Navigation("/SignIn")}>
                    <a className="d-flex justify-content-between">
                      <span className="text"> {t("Login")}</span>
                      <span className="icons">
                        <i className="bi bi-chevron-right"></i>
                      </span>
                    </a>
                  </li>
                  {adminDetails && adminDetails.Role === "SuperAdmin" && (
                    <li onClick={() => Navigation("/BotList")}>
                      <a className="d-flex justify-content-between">
                        <span className="text">{t("Bot management")}</span>
                        <span className="icons">
                          <i className="bi bi-chevron-right"></i>
                        </span>
                      </a>
                    </li>
                  )}

                  {adminDetails &&
                    (adminDetails.Role === "SuperAdmin" ||
                      adminDetails.Role === "Admin") && (
                      <li onClick={() => handleNavigatetoProfileManagement()}>
                        <a className="d-flex justify-content-between">
                          <span className="text">
                            {t("Profile management")}
                          </span>
                          <span className="icons">
                            <i className="bi bi-chevron-right"></i>
                          </span>
                        </a>
                      </li>
                    )}

                  {adminDetails && adminDetails.Role === "SuperAdmin" && (
                    <li onClick={() => Navigation("/WebsiteConfiguration")}>
                      <a className="d-flex justify-content-between">
                        <span className="text">
                          {t("Website Configuration")}
                        </span>
                        <span className="icons">
                          <i className="bi bi-chevron-right"></i>
                        </span>
                      </a>
                    </li>
                  )}
                  {adminDetails && adminDetails.Role === "SuperAdmin" && (
                    <li onClick={() => Navigation("/ManageAdmin")}>
                      <a className="d-flex justify-content-between">
                        <span className="text"> {t("Manage Admins")}</span>
                        <span className="icons">
                          <i className="bi bi-chevron-right"></i>
                        </span>
                      </a>
                    </li>
                  )}
                  {adminDetails &&
                    (adminDetails.Role === "SuperAdmin" ||
                      adminDetails.Role === "Admin") && (
                      <li>
                        <a
                          className="d-flex justify-content-between"
                          onClick={() => Navigation("/ManageUser")}
                        >
                          <span className="text">{t("Manage User")}</span>
                          <span className="icons">
                            <i className="bi bi-chevron-right"></i>
                          </span>
                        </a>
                      </li>
                    )}
                  {adminDetails &&
                    (adminDetails.Role === "SuperAdmin" ||
                      adminDetails.Role === "Admin") && (
                      <li onClick={() => Navigation("/AnnouncementList")}>
                        <a className="d-flex justify-content-between">
                          <span className="text">{t("Announcement")}</span>
                          <span className="icons">
                            <i className="bi bi-chevron-right"></i>
                          </span>
                        </a>
                      </li>
                    )}
                  {/* {adminDetails && adminDetails.Role === "SuperAdmin" && (
                    <li onClick={() => Navigation("/PriceReview")}>
                      <a className="d-flex justify-content-between">
                        <span className="text">{t("Price Review")}</span>
                        <span className="icons">
                          <i className="bi bi-chevron-right"></i>
                        </span>
                      </a>
                    </li>
                  )} */}
                  <li onClick={() => Navigation("/UserWallet")}>
                    <a className="d-flex justify-content-between">
                      <span className="text">{t("Wallet Configuration")}</span>
                      <span className="icons">
                        <i className="bi bi-chevron-right"></i>
                      </span>
                    </a>
                  </li>
                  <li onClick={() => handleSupportGroupClick()}>
                    <a className="d-flex justify-content-between">
                      <span className="text">{t("User Guide")}</span>
                      <span className="icons">
                        <i className="bi bi-chevron-right"></i>
                      </span>
                    </a>
                  </li>
                  <li onClick={() => Navigation("/SelectLanguage")}>
                    <a className="d-flex justify-content-between">
                      <span className="text">{t("Language")}</span>
                      <span className="icons">
                        <i className="bi bi-chevron-right"></i>
                      </span>
                    </a>
                  </li>
                  <li onClick={() => handleSupportGroupClick()}>
                    <a className="d-flex justify-content-between">
                      <span className="text">{t("Advertise with us")}</span>
                      <span className="icons">
                        <i className="bi bi-chevron-right"></i>
                      </span>
                    </a>
                  </li>
                  <li onClick={() => handleSupportGroupClick()}>
                    <a className="d-flex justify-content-between">
                      <span className="text">{t("Use Cases")}</span>
                      <span className="icons">
                        <i className="bi bi-chevron-right"></i>
                      </span>
                    </a>
                  </li>
                  <li onClick={() => handleSupportGroupClick()}>
                    <a className="d-flex justify-content-between">
                      <span className="text">{t("Contact Us")}</span>
                      <span className="icons">
                        <i className="bi bi-chevron-right"></i>
                      </span>
                    </a>
                  </li>
                  <li onClick={() => handleSupportGroupClick()}>
                    <a className="d-flex justify-content-between">
                      <span className="text">{t("Recommend Us")}</span>
                      <span className="icons">
                        <i className="bi bi-chevron-right"></i>
                      </span>
                    </a>
                  </li>
                  <li onClick={() => Navigation("/Referrals")}>
                    <a className="d-flex justify-content-between">
                      <span className="text">{t("Invite Friends")}</span>
                      <span className="icons">
                        <i className="bi bi-chevron-right"></i>
                      </span>
                    </a>
                  </li>
                  <li onClick={() => Navigation("/FacebookAdList")}>
                    <a className="d-flex justify-content-between">
                      <span className="text">{t("Ad Management")}</span>
                      <span className="icons">
                        <i className="bi bi-chevron-right"></i>
                      </span>
                    </a>
                  </li>
                  {adminDetails && adminDetails.Role === "SuperAdmin" && (
                    <li onClick={() => Navigation("/PaymentSettings")}>
                      <a className="d-flex justify-content-between">
                        <span className="text">{t("Payment Settings")}</span>
                        <span className="icons">
                          <i className="bi bi-chevron-right"></i>
                        </span>
                      </a>
                    </li>
                  )}
                  <li onClick={() => handleSupportGroupClick()}>
                    <a className="d-flex justify-content-between">
                      <span className="text">{t("Payment Terms")}</span>
                      <span className="icons">
                        <i className="bi bi-chevron-right"></i>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="footer">
            <div className="container-fluid">
              <div className="inner-footer">
                <div className="d-flex justify-content-between">
                  <div className="footer-box">
                    <a className="" onClick={() => Navigation("/")}>
                      <svg width="25" height="25" viewBox="0 0 25 25">
                        <path d="M13.8801 2.11602C13.4324 1.73537 12.8638 1.52637 12.2761 1.52637C11.6884 1.52637 11.1199 1.73537 10.6721 2.11602L3.24261 8.43071C2.96914 8.66318 2.74948 8.95232 2.59883 9.27809C2.44818 9.60387 2.37014 9.9585 2.37012 10.3174V20.1929C2.37012 20.6982 2.57082 21.1827 2.92808 21.54C3.28534 21.8972 3.76988 22.0979 4.27512 22.0979H8.08512C8.59036 22.0979 9.0749 21.8972 9.43216 21.54C9.78941 21.1827 9.99012 20.6982 9.99012 20.1929V14.8589C9.99012 14.7579 10.0303 14.661 10.1017 14.5895C10.1732 14.5181 10.2701 14.4779 10.3711 14.4779H13.8001C13.9012 14.4779 13.9981 14.5181 14.0695 14.5895C14.141 14.661 14.1811 14.7579 14.1811 14.8589V20.1929C14.1811 20.6982 14.3818 21.1827 14.7391 21.54C15.0963 21.8972 15.5809 22.0979 16.0861 22.0979H20.2771C20.7824 22.0979 21.2669 21.8972 21.6242 21.54C21.9814 21.1827 22.1821 20.6982 22.1821 20.1929V10.3174C22.1821 9.9585 22.1041 9.60387 21.9534 9.27809C21.8028 8.95232 21.5831 8.66318 21.3096 8.43071L13.8801 2.11602Z" />
                      </svg>
                      <span>{t("Home")}</span>
                    </a>
                  </div>
                  <div className="footer-box">
                    <a
                      className=""
                      onClick={() => Navigation("/SuperAdminProfile")}
                    >
                      <svg width="21" height="21" viewBox="0 0 21 21">
                        <path d="M10.8282 0.0319824C5.32048 0.0319824 0.668213 4.68425 0.668213 10.192C0.668213 15.6997 5.32048 20.352 10.8282 20.352C16.3359 20.352 20.9882 15.6997 20.9882 10.192C20.9882 4.68425 16.3359 0.0319824 10.8282 0.0319824ZM10.8282 5.11198C12.5828 5.11198 13.8762 6.40433 13.8762 8.15998C13.8762 9.91563 12.5828 11.208 10.8282 11.208C9.0746 11.208 7.78021 9.91563 7.78021 8.15998C7.78021 6.40433 9.0746 5.11198 10.8282 5.11198ZM5.64052 15.0403C6.55187 13.6992 8.07181 12.8051 9.81221 12.8051H11.8442C13.5856 12.8051 15.1046 13.6992 16.0159 15.0403C14.7175 16.4302 12.8755 17.304 10.8282 17.304C8.78097 17.304 6.93897 16.4302 5.64052 15.0403Z" />
                      </svg>
                      <span>{t("Profile")}</span>
                    </a>
                  </div>
                </div>
                <div className="add-upload">
                  <a
                    className="btn-upload"
                    onClick={() => Navigation("/CreateTask")}
                  >
                    <svg width="33" height="34" viewBox="0 0 33 34">
                      <path d="M19 3.25V14.5H30.25C30.9792 14.5 31.5781 14.7344 32.0469 15.2031C32.5156 15.6719 32.75 16.2708 32.75 17C32.75 17.7292 32.5156 18.3281 32.0469 18.7969C31.5781 19.2656 30.9792 19.5 30.25 19.5H19V30.75C19 31.4792 18.7656 32.0781 18.2969 32.5469C17.8281 33.0156 17.2292 33.25 16.5 33.25C15.7708 33.25 15.1719 33.0156 14.7031 32.5469C14.2344 32.0781 14 31.4792 14 30.75V19.5H2.75C2.02083 19.5 1.42188 19.2656 0.953125 18.7969C0.484375 18.3281 0.25 17.7292 0.25 17C0.25 16.2708 0.484375 15.6719 0.953125 15.2031C1.42188 14.7344 2.02083 14.5 2.75 14.5H14V3.25C14 2.52083 14.2344 1.92188 14.7031 1.45312C15.1719 0.984375 15.7708 0.75 16.5 0.75C17.2292 0.75 17.8281 0.984375 18.2969 1.45312C18.7656 1.92188 19 2.52083 19 3.25Z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuperAdminProfile;
