// GroupListCardContext.js
import React, { createContext, useContext, useState } from "react";

const AnnouncementGroupListContext = createContext();
export const useAnnouncementGroupListContext = () =>
  useContext(AnnouncementGroupListContext);
export const AnnouncementGroupListProvider = ({ children }) => {
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const handleGroupSelection = (groupId, groupName, groupTgId) => {
    const index = selectedGroups.findIndex((group) => group.id === groupId);
    if (index === -1) {
      setSelectedGroups((prevSelectedGroups) => [
        ...prevSelectedGroups,
        { id: groupId, name: groupName, groupId: groupTgId },
      ]);
    } else {
      setSelectedGroups((prevSelectedGroups) =>
        prevSelectedGroups.filter((group) => group.id !== groupId)
      );
    }
  };

  return (
    <AnnouncementGroupListContext.Provider
      value={{
        groups,
        setGroups,
        selectedGroups,
        handleGroupSelection,
        setSelectedGroups,
      }}
    >
      {children}
    </AnnouncementGroupListContext.Provider>
  );
};
