import React, { createContext, useContext, useState } from "react";
const AnnouncementGroupContext = createContext();

export const useAnnouncementGroupContext = () =>
  useContext(AnnouncementGroupContext);

// Provider component to wrap your application
export const AnnouncementGroupProvider = ({ children }) => {
  const [selectedGroupData, setSelectedgroupData] = useState(null);

  return (
    <AnnouncementGroupContext.Provider
      value={{ selectedGroupData, setSelectedgroupData }}
    >
      {children}
    </AnnouncementGroupContext.Provider>
  );
};
