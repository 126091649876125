import React, { createContext, useContext, useState } from "react";

const LinkContext = createContext();

export const useLinkContext = () => useContext(LinkContext);

export const SelectedLinkProvider = ({ children }) => {
  const [inputs, setInputs] = useState([{ id: 1, value: "" }]);
  const [selectedlinkData, setselectedlinkData] = useState(null);

  const addInput = () => {
    const newInput = { id: inputs[inputs.length - 1].id + 1, value: "" };
    setInputs([...inputs, newInput]);
  };

  const removeInput = (index) => {
    const values = [...inputs];
    values.splice(index, 1);
    setInputs(values);
  };

  // const updateInput = (index, value) => {
  //   const values = [...inputs];
  //   values[index].value = value;
  //   setInputs(values);
  // };

  const updateInput = (index, value) => {
    const values = [...inputs];
    if (values[index]) {
      values[index].value = value;
      setInputs(values);
    } else {
      console.error(`No input found at index ${index}`);
    }
  };
  const showData = (navigate) => {
    const validInputs = inputs.filter((input) => validateInput(input.value));
    setselectedlinkData(validInputs);
    navigate("/CreateTask", { state: { LinkData: validInputs } });
  };

  const validateInput = (input) => {
    const regex = /^(https:\/\/|@)[^\s]+$/;
    return regex.test(input);
  };

  return (
    <LinkContext.Provider
      value={{
        inputs,
        addInput,
        removeInput,
        updateInput,
        selectedlinkData,
        showData,
        setselectedlinkData,
        setInputs,
      }}
    >
      {children}
    </LinkContext.Provider>
  );
};
