import React, { useEffect, useState } from "react";
import themeBg from "../../../assets/images/theme-bg-dark.png";
import { MdOutlineAppBlocking } from "react-icons/md";
import {
  openTelegramLink,
  UserDetails,
} from "../../../Helper/TgMiniAppFunctions";
import { useCustomAlert } from "../../../Helper/Alerts";
import { Get } from "../../../Helper/ApiServices";
import { GetAdminUsernameByTGUserId } from "../../../Helper/UrlConstant";
import { encrypt } from "../../../Helper/EncryptionHelper";
import { useTranslation } from "react-i18next";

export const UserRestricted = () => {
  //Const Declarartion
  const user = UserDetails();
  //hooks
  const showAlert = useCustomAlert();
  const { t } = useTranslation();

  //Api functions
  const fetchAdminUsername = async () => {
    const params = {
      TGUserId:
        user != null && user != undefined ? await encrypt(String(user.id)) : "",
      BotId: await encrypt(localStorage.getItem("BotId")),
    };
    const response = await Get(GetAdminUsernameByTGUserId, params);
    if (response && response.data) {
      return response.data;
    } else if (response && response.statusCode !== 200) {
      showAlert("", t("Something Went Wrong"), "error");
    }
  };

  //Handle Function
  const handleContactAdmin = async () => {
    const admindata = await fetchAdminUsername();
    if (admindata && admindata.username) {
      const url = `https://t.me/${admindata?.username}`;
      openTelegramLink(url);
    } else {
      showAlert("", t("Admin Not Found"), "error");
    }
  };
  return (
    <div id="main-content" className="main-content" style={{ height: "100vh" }}>
      <div
        className="bg-image"
        style={{ backgroundImage: `url(${themeBg})` }}
      ></div>
      <div className="container-fluid" style={{ height: "100%" }}>
        <div
          className="p-2"
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div className="box-with-form">
            <div className="">
              <div className="d-flex justify-content-center align-items-center">
                <MdOutlineAppBlocking size={50} />
              </div>
              <div className="text-center">
                <h5>{t("Your account has been blocked!")}</h5>
                <p style={{ color: "gray" }}>
                  {t(
                    "Please contact admin if you think this is an error. See also terms of service."
                  )}
                </p>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <button
                  className="btn btn-primary w-100"
                  onClick={handleContactAdmin}
                >
                  {t("Contact Admin")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
