import React, { useEffect, useState } from "react";
//Importing Css and assets
import style from "../Css/CreateTask.module.css";

//Importing Css asnd assests and here

//Importing Context
import { useTranslation } from "react-i18next";
import { usePrizeContext } from "../../../Context/PrizeContext";
import { useFormContext } from "../../../Context/CustomFormContext";
import { UserDetails } from "../../../Helper/TgMiniAppFunctions";
import { useCustomAlert } from "../../../Helper/Alerts";
//Importing Contexts

export const PrizeTypeComponent = ({ onData, currencies }) => {
  //Context Comes here
  const { prizes, setPrizes } = usePrizeContext();
  const {
    touchedAmount,
    setTouchedAmount,
    touchedPrizeName,
    setTouchedPrizeName,
    touchedQty,
    setTouchedQty,
    touchedCurrency,
    setTouchedCurrency,
  } = usePrizeContext();
  const { flag, setflag } = useFormContext();
  const { QtyFlag, setQtyFlag } = useFormContext();
  // const [currencies, setCurrencies] = useState();
  const user = UserDetails();
  const showAlert = useCustomAlert();

  //Hooks
  const { t } = useTranslation();

  useEffect(() => {
    onData(prizes);
  }, [prizes]);

  //Functions
  const addComponent = () => {
    const newPrize = {
      id: prizes[prizes.length - 1].id + 1,
      PrizeType: "Amount",
      CurrencyType: "",
      Amount: 0,
      PrizeName: "",
      Quantity: 0,
      amountTouched: false,
      qtyTouched: false,
      prizeNameTouched: false,
      currencyTouched: false,
    };
    setPrizes([...prizes, newPrize]);
  };

  const removeComponent = (id) => {
    setPrizes(prizes.filter((prize) => prize.id !== id));
  };

  const validateAmount = (amount) => {
    // setSelectedCustomTask.flag = touchedAmount && amount <= 0 ;

    setflag(touchedAmount && amount <= 0);

    return touchedAmount && amount <= 0; // Show error only when touched and amount is invalid
  };

  const validatePrizeName = (prizeName) => {
    // setSelectedCustomTask.flag = touchedAmount && amount <= 0 ;

    setflag(touchedPrizeName && prizeName === "");

    return touchedPrizeName && prizeName === ""; // Show error only when touched and amount is invalid
  };

  const validateQty = (qty) => {
    setQtyFlag(touchedQty && qty <= 0);
    return touchedQty && qty <= 0;
  };
  //Functions Ends Here
  //Input Handles Comes here
  const setPrizeType = (id, type) => {
    setPrizes(
      prizes.map((prize) =>
        prize.id === id ? { ...prize, PrizeType: type } : prize
      )
    );
  };

  const setCurrencyType = (id, currency) => {
    // Check if any other prize has a different currency selected
    const selectedCurrency = prizes.find(
      (prize) => prize.CurrencyType && prize.id !== id
    )?.CurrencyType;

    if (selectedCurrency && selectedCurrency !== currency) {
      showAlert(
        "",
        t("You cannot select different currencies for different prizes."),
        "error"
      );
      return;
    }

    setPrizes(
      prizes.map((prize) =>
        prize.id === id ? { ...prize, CurrencyType: currency } : prize
      )
    );
  };

  const setAmount = (id, amount) => {
    setPrizes(
      prizes.map((prize) =>
        prize.id === id ? { ...prize, Amount: amount } : prize
      )
    );
  };

  const setPrizeName = (id, prizeName) => {
    setPrizes(
      prizes.map((prize) =>
        prize.id === id ? { ...prize, PrizeName: prizeName } : prize
      )
    );
  };
  const validateCurrency = (currency) => {
    return touchedCurrency && !currency;
  };
  const setQuantity = (id, quantity) => {
    setPrizes(
      prizes.map((prize) =>
        prize.id === id ? { ...prize, Quantity: quantity } : prize
      )
    );
  };
  const handleAmountClick = (id) => {
    setTouchedAmount(true);
    setPrizes(
      prizes.map((prize) =>
        prize.id === id ? { ...prize, amountTouched: true } : prize
      )
    );
  };

  const handlePrizeNameClick = (id) => {
    setTouchedPrizeName(true);
    setPrizes(
      prizes.map((prize) =>
        prize.id === id ? { ...prize, prizeNameTouched: true } : prize
      )
    );
  };

  const handleQtyClick = (id) => {
    setTouchedQty(true);
    setPrizes(
      prizes.map((prize) =>
        prize.id === id ? { ...prize, qtyTouched: true } : prize
      )
    );
  };
  const onGiftSelect = () => {
    setflag(false);
  };

  const onAmountSelect = () => {
    setflag(false);
  };

  //Input handles Ends Here
  const handleCurrencyClick = (id) => {
    setTouchedCurrency(true);
    setPrizes(
      prizes.map((prize) =>
        prize.id === id ? { ...prize, currencyTouched: true } : prize
      )
    );
  };
  return (
    <>
      {prizes !== undefined && prizes !== null
        ? prizes.map((prize, index) => (
            <div key={prize.id}>
              <div className="form-group input-single">
                <label>{t("Prize Type")}</label>
                <select
                  id={`prizeType${index}`}
                  name={`prizeType${index}`}
                  className="form-control"
                  onChange={(event) => {
                    setPrizeType(prize.id, event.target.value);
                    onData(prizes);
                  }}
                  value={prize.PrizeType}
                >
                  <option value="Amount" onChange={onAmountSelect}>
                    {t("Amount")}
                  </option>
                  <option value="Gift" onChange={onGiftSelect}>
                    {t("Gift")}
                  </option>
                </select>
              </div>
              {prize.PrizeType === "Gift" && (
                <div key={prize.id} className="form-group input-single ">
                  <label>{t("Prize Name")}</label>
                  <input
                    type="text"
                    className="form-control"
                    id={`prizeName${index}`}
                    name={`prizeName${index}`}
                    value={prize.PrizeName}
                    onChange={(event) => {
                      setPrizeName(prize.id, event.target.value);
                      onData(prizes);
                    }}
                    onClick={(event) => {
                      handlePrizeNameClick(prize.id);
                    }}
                    onBlur={(event) => {
                      handlePrizeNameClick(prize.id);
                    }}
                    placeholder={t("Enter Prize Name")}
                  />
                  {prize.prizeNameTouched && (
                    <div>
                      {validatePrizeName(prize.PrizeName) && (
                        <span className="text-danger">
                          {t("Prize Name is required")}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}
              {prize.PrizeType === "Amount" && (
                <div
                  className={`${style["price-box-left-right"]} form-group input-single d-flex  `}
                >
                  <div className={`${style["left-group"]} w-50`}>
                    <label>{t("Enter Amount")}</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder={t("Enter Amount")}
                      min={0}
                      // onScroll={true}
                      id={`amount${index}`}
                      name={`amount${index}`}
                      value={prize.Amount}
                      onChange={(event) => {
                        setAmount(prize.id, event.target.value);
                        onData(prizes);
                      }}
                      onClick={(event) => {
                        handleAmountClick(prize.id);
                      }}
                      onBlur={(event) => {
                        handleAmountClick(prize.id);
                      }}
                    />
                    {prize.amountTouched && (
                      <div>
                        {validateAmount(prize.Amount) && (
                          <span className="text-danger">
                            {t("Amount is required")}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  <div className={`${style["right-group"]} w-50 ms-2`}>
                    <label></label>
                    <select
                      className="form-control"
                      name={`currencySelect${index}`}
                      id={`currencySelect${index}`}
                      value={prize.CurrencyType} // Assuming prize.CurrencyType holds the selected currency value
                      onChange={(event) => {
                        setCurrencyType(prize.id, event.target.value); // Assuming setCurrencyType is your setter function
                        onData(prizes); // Assuming onData function updates the state with prizes
                      }}
                    >
                      <option value="">{t("Select Currency")}</option>
                      <option value="TON.ERC20">
                        Wrapped TON Coin (ERC20) - TON.ERC20
                      </option>
                      <option value="USDT.BEP2">
                        Tether USD (BC Chain) - USDT.BEP2
                      </option>
                      <option value="USDT.BEP20">
                        Tether USD (BSC Chain) - USDT.BEP20
                      </option>
                      <option value="LTCT">Litecoin Testnet - LTCT</option>
                      {/* {currencies &&
                        currencies.map((currency) => (
                          <option value={currency.currencyCode}>
                            {currency.coinName}
                          </option>
                        ))} */}
                    </select>
                    {prize.currencyTouched &&
                      validateCurrency(prize.CurrencyType) && (
                        <span className="text-danger">
                          {t("Currency is required")}
                        </span>
                      )}
                  </div>
                </div>
              )}

              <div className="form-group input-single">
                <label>{t("Quantity")}</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder={t("Enter Quantity")}
                  min={0}
                  id={`quantity${index}`}
                  name={`quantity${index}`}
                  value={prize.Quantity}
                  onChange={(event) => {
                    setQuantity(prize.id, event.target.value);
                    onData(prizes);
                  }}
                  onClick={(event) => {
                    handleQtyClick(prize.id);
                  }}
                  onBlur={(event) => {
                    handleQtyClick(prize.id);
                  }}
                />
                {prize.qtyTouched && (
                  <div>
                    {" "}
                    {validateQty(prize.Quantity) && (
                      <span className="text-danger">
                        {t("Quantity should be greater than 0")}
                      </span>
                    )}
                  </div>
                )}
              </div>
              {index === prizes.length - 1 && (
                <div className="form-actions-button mt-1 mb-2">
                  <a className="btn btn-primary w-100" onClick={addComponent}>
                    {t("Add Prize")}
                  </a>
                </div>
              )}
              {prizes.length > 1 && (
                <div className="form-actions-button mt-1 mb-2">
                  <a
                    className="btn btn-delete w-100"
                    onClick={() => {
                      removeComponent(prize.id);
                      onData(prizes);
                    }}
                  >
                    {t("Remove Prize")}
                  </a>
                </div>
              )}
            </div>
          ))
        : prizes}
    </>
  );
};
