import axios from "axios";
import useSWR from "swr";
import Cookies from "js-cookie";
//Get token to get the Token from the cookies
export const getToken = () => {
  return Cookies.get("token");
};
const getAdminToken = () => {
  return Cookies.get("qEWbpONT");
};

export const Get = async (url, params = {}, IsAdmin) => {
  try {
    let token = null;
    if (IsAdmin !== null && IsAdmin !== undefined) {
      token = getAdminToken();
    } else {
      token = getToken();
    }

    if (token !== undefined && token !== null) {
      const response = await axios.get(url, {
        params,
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the header
        },
      });
      if (response.status === 200) {
        return response.data; // Return the data property from the API response
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    }
  } catch (error) {
    console.error("Error fetching data from API:", error);
    throw error;
  }
};

// export const useGet = (url, params = null) => {
//   const token = getToken(); // Retrieve token once to check if it exists
//   const { data, error } = useSWR(
//     token ? (params ? [url, params] : url) : null,
//     fetcher,
//     {
//       shouldRetryOnError: false,
//       revalidateOnFocus: true,
//       revalidateOnReconnect: true,
//     }
//   );

//   return {
//     data,
//     isLoading: !error && !data,
//     isError: error,
//   };
// };
// Fetcher function
// Fetcher function
const fetcher = async (args) => {
  const [url, params] = Array.isArray(args) ? args : [args];
  const token = getToken();
  if (token !== undefined && token !== null) {
    const response = await axios.get(url, {
      params,
      headers: {
        Authorization: `Bearer ${token}`, // Add the token to the header
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  }
};

// Custom hook for GET requests
export const useGet = (url, params = null) => {
  const { data, error } = useSWR(params ? [url, params] : url, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
// const fetcher = async (url, params) => {
//   const token = getToken();
//   const response = await axios.get(url, {
//     params,
//     headers: {
//       Authorization: `Bearer ${token}`, // Add the token to the header
//     },
//   });
//   if (response.status === 200) {
//     return response.data;
//   } else {
//     throw new Error(`Unexpected response status: ${response.status}`);
//   }
// };

// export const useGet = (url, params = null) => {
//   const { data, error } = useSWR(params ? [url, params] : url, fetcher, {
//     shouldRetryOnError: false,
//     revalidateOnFocus: true,
//     revalidateOnReconnect: true,
//   });

//   return {
//     data,
//     isLoading: !error && !data,
//     isError: error,
//   };
// };

// function to call Post api method ,pass url and data
export const Post = async (url, data, IsAdmin) => {
  try {
    if (!data || Object.keys(data).length === 0) {
      throw new Error("Data cannot be empty");
    }
    let token = null;
    if (IsAdmin !== null && IsAdmin !== undefined) {
      token = getAdminToken();
    } else {
      token = getToken();
    }
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error sending data to API:", error);
    throw error;
  }
};
