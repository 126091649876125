import Swal from "sweetalert2";
import { useAlert } from "react-alert";

export function AlertInfo(
  title,
  message,
  showConfirmButton,
  confirmbuttontext
) {
  return Swal.fire({
    title: title,
    text: message,
    icon: "info",
    width: "300px",
    showConfirmButton: showConfirmButton,
    confirmButtonText: confirmbuttontext,

    customClass: {
      popup: "animate__animated animate__slideInUp",
    },
  });
}

// Custom function to show alerts
export function useCustomAlert() {
  const alert = useAlert();
  function showAlert(
    title,
    message,
    type = "info",
    showConfirmButton = true,
    confirmButtonText = "OK"
  ) {
    const alertOptions = {
      type: type,
      title: title,
      message: message,
      confirmButtonText: confirmButtonText,
    };
    // Display the alert
    alert.show(message, alertOptions);
  }

  return showAlert;
}

export function AlertSuccess(
  title,
  message,
  showConfirmButton,
  confirmbuttontext
) {
  Swal.fire({
    title: title,
    text: message,
    icon: "success",
    width: "300px",
    showConfirmButton: showConfirmButton,
    confirmButtonText: confirmbuttontext,

    customClass: {
      popup: "animate__animated animate__slideInUp",
    },
  });
}
export function AlertWarning(
  title,
  message,
  showConfirmButton,
  confirmbuttontext
) {
  Swal.fire({
    title: title,
    text: message,
    icon: "warning",
    width: "300px",
    showConfirmButton: showConfirmButton,
    confirmButtonText: confirmbuttontext,

    customClass: {
      popup: "animate__animated animate__slideInUp",
    },
  });
}

export function AlertQuestion(
  title,
  message,
  showConfirmButton,
  confirmbuttontext
) {
  Swal.fire({
    title: title,
    text: message,
    icon: "question",
    width: "300px",
    showConfirmButton: showConfirmButton,
    confirmButtonText: confirmbuttontext,

    customClass: {
      popup: "animate__animated animate__slideInUp",
    },
  });
}
