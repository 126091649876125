import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// Import translation files
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';
import translationCH from './locales/ch/translation.json';
import translationCHL from './locales/chl/translation.json';
import translationPU from './locales/pu/translation.json';
import translationPUB from './locales/pub/pub.json';
const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  },
  ch: {
    translation: translationCH
  },
  chl: {
    translation: translationCHL
  },
  pu: {
    translation: translationPU
  },
  pub: {
    translation: translationPUB
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['queryString', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie']
    }
  });

export default i18n;
