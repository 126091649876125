import React, { createContext, useContext, useState } from "react";
const AnnouncementContext = createContext();

export const useAnnouncementContext = () => useContext(AnnouncementContext);

// Provider component to wrap your application
export const AnnouncementProvider = ({ children }) => {
  const [announcementDescription, setAnnouncementDesription] = useState(null);
  const [announcementName, setAnnouncementName] = useState(null);
  const [IsAnnounceToBot, setIsAnnounceToBot] = useState(false);
  const [IsAnnounceToMiniApp, setIsAnnounceToMiniApp] = useState(false);
  return (
    <AnnouncementContext.Provider
      value={{
        announcementDescription,
        setAnnouncementDesription,
        IsAnnounceToBot,
        setIsAnnounceToBot,
        IsAnnounceToMiniApp,
        setIsAnnounceToMiniApp,
        announcementName,
        setAnnouncementName,
      }}
    >
      {children}
    </AnnouncementContext.Provider>
  );
};
