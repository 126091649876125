import { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

// import SignUp from "./Components/Login/Components/SignUp.jsx";
// import SignIn from "./Components/Login/Components/SignIn.jsx";
import { Home } from "./Components/Home/Components/Home.jsx";
// import BotList from "./Components/BotManagement/Components/BotList.jsx";
// import BotNotFound from "./Components/BotManagement/Components/BotNotFound.jsx";
// import AddBot from "./Components/BotManagement/Components/AddBot.jsx";
// import BotDetails from "./Components/BotManagement/Components/BotDetails.jsx";
// import { ChangePassword } from "./Components/Account/Components/ChangePassword.jsx";
// import Settings from "./Components/ManageUser/Components/Settings.jsx";
// import ForgotPassword from "./Components/Account/Components/ForgotPassword.jsx";
// import EditBotDetails from "./Components/BotManagement/Components/EditBotDetails.jsx";
// import ManageUser from "./Components/ManageUser/Components/ManageUser.jsx";
import CreateTask from "./Components/CustomTask/Component/CreateTask.jsx";
// import { TaskDetails } from "./Components/TaskDetails/Components/TaskDetails.jsx";
// import SelectedChannelList from "./Components/JoinChannels/Components/SelectedChannelList.jsx";
// import JoinGroupList from "./Components/JoinGroups/Components/JoinGroupList.jsx";
// import ClickLink from "./Components/ClickLink/Components/ClickLink.jsx";
// import Leaderboard from "./Components/TaskDetails/Components/Leaderboard.jsx";
// import Winner from "./Components/TaskDetails/Components/Winner.jsx";
// import DrawEnded from "./Components/TaskDetails/Components/DrawEnded.jsx";
import DrawRules from "./Components/TaskDetails/Components/DrawRules.jsx";

// Importing Context
import { CustomFormProvider } from "./Context/CustomFormContext.js";
import { PrizeProvider } from "./Context/PrizeContext.js";
import { ChannelListCardProvider } from "./Context/ChannelListCardContext.js";
import { ChannelProvider } from "./Context/ChannelContext.js";
import { GroupListCardProvider } from "./Context/GroupListCardContext.js";
import { GroupProvider } from "./Context/GroupContext.js";
import { SelectedLinkProvider } from "./Context/SelectedlinkContext.js";
import { ParticipantDeadlineProvider } from "./Context/ParticipantDeadlineContext .js";
import { ImageProvider } from "./Context/ImageContext.js";
import { DrawRulesProvider } from "./Context/DrawRulesContext.js";
import { SignInProvider } from "./Context/SignInContext.js";
import { SignUpProvider } from "./Context/SignUpContext.js";
import { AnnouncementChannelListProvider } from "./Context/AnnouncementChannelList.js";
import { AnnouncementProvider } from "./Context/AnnouncementContext.js";
import { AnnouncementChannelProvider } from "./Context/AnnouncementChannelContext.js";
import { AnnouncementGroupListProvider } from "./Context/AnnouncementGroupListContext.js";
import { AnnouncementGroupProvider } from "./Context/AnnouncementGroupContext.js";
import { SpecificRegionProvider } from "./Context/SpecificRegionContext.js";
// import { SocialMediaLinkProvider } from "./Context/SocialMediaLinkContext.js";
import { FacebookContextProvider } from "./Context/FacebookContext.js";

// import Enter from "./Components/TaskDetails/Components/Enter.jsx";
//Importing Context Ends Here
// const SuperAdminProfile = lazy(() =>
//   import("./Components/ProfileManagement/Components/SuperAdminProfile.jsx")
// );
import SuperAdminProfile from "./Components/ProfileManagement/Components/SuperAdminProfile.jsx";
// import ProfileManagement from "./Components/ProfileManagement/Components/ProfileManagement.jsx";
// import EditUserName from "./Components/ProfileManagement/Components/EditUsername.jsx";
// import EditPassword from "./Components/ProfileManagement/Components/EditPassword.jsx";
// import WebsiteConfiguration from "./Components/ManageUser/Components/WebsiteConfiguration.jsx";
// import CreateAnnouncement from "./Components/AnnouncementManagement/Component/CreateAnnouncement.jsx";
// import AnnouncementGroups from "./Components/AnnouncementManagement/Component/AnnouncementGroups.jsx";
// import AnnouncementChannels from "./Components/AnnouncementManagement/Component/AnnouncementChannels.jsx";
// import AnnouncementList from "./Components/AnnouncementManagement/Component/AnnouncementList.jsx";
// import AnnouncementMessage from "./Components/AnnouncementManagement/Component/AnnouncementMessage.jsx";
// import AnnouncementDetails from "./Components/AnnouncementManagement/Component/AnnouncementDetails.jsx";
import SelectLanguage from "./Components/ProfileManagement/Components/SelectLanguage.jsx";
// import EditTask from "./Components/CustomTask/Component/EditTask.jsx";
// import EntrantsUserList from "./Components/TaskDetails/Components/EntrantsUserList.jsx";
// import CreatedTaskList from "./Components/ProfileManagement/Components/CreatedTaskList.jsx";
import { SplashScreen } from "./Components/SplashScreen/Components/SplashScreen.jsx";
import { SharingLinkPopUp } from "./Components/TaskDetails/Components/SharingLinkPopUp.jsx";
import {
  initializeTelegramTheme,
  tgStart_Param,
  UserDetails,
} from "./Helper/TgMiniAppFunctions.jsx";
import { useCustomAlert } from "./Helper/Alerts.jsx";
import Cookies from "js-cookie";
import axios from "axios";
// import UserDetailsComponent from "./Components/ManageUser/Components/UserDetailsComponent.jsx";
import { UserRestricted } from "./Components/ManageUser/Components/UserRestricted.jsx";
import { getQueryParam } from "./Helper/QueryParamsHelper.jsx";
import { decrypt, encrypt } from "./Helper/EncryptionHelper.jsx";
// import ParticipatedTaskList from "./Components/ProfileManagement/Components/ParticipatedTaskList.jsx";
// import ManageAdmin from "./Components/ManageAdmins/Components/ManageAdmin.jsx";
// import  AdminDetails  from "./Components/ManageAdmins/Components/AdminDetails.jsx";
import PrivateRoute from "./PrivateRoute.jsx";
// import SpecificRegion from "./Components/SpecificRegion/Components/SpecificRegion.jsx";

// import Referrals from "./Components/Referal/Components/Referral.jsx";
// import FacebookAd  from "./Components/FacebookAds/Component/FacebookAd.jsx";
// import { TonConnectUIProvider } from "@tonconnect/ui-react";
// import { AccountManagement } from "./Components/AccountManagement/Components/AccountManagement.jsx";
// import FacebookAdList from "./Components/FacebookAds/Component/FacebookAdList.jsx";
// import FacebookAdDetails from "./Components/FacebookAds/Component/FacebookAdDetails.jsx";
// import AmountPending from "./Components/Payment/Components/AmountPending.jsx";
// import  PaymentSettings  from "./Components/Payment/Components/PaymentSettings.jsx";
// import { PriceReview } from "./Components/PriceReview/Components/PriceReview.jsx";
// import UserWallet from "./Components/Userwallet/Components/UserWallet.jsx";
import { AddReferal, CreateUser } from "./Helper/UrlConstant.js";
import { useTranslation } from "react-i18next";
import { decodeBase64 } from "./Helper/Decoder.jsx";
import { Post } from "./Helper/ApiServices.jsx";
// import { SpecificRegion } from "./Components/SpecificRegion/Components/SpecificRegion.jsx";

// const SuperAdminProfile = lazy(() =>
//   import("./Components/ProfileManagement/Components/SuperAdminProfile.jsx")
// );
const SignUp = lazy(() => import("./Components/Login/Components/SignUp.jsx"));

const SignIn = lazy(() => import("./Components/Login/Components/SignIn.jsx"));
const BotList = lazy(() =>
  import("./Components/BotManagement/Components/BotList.jsx")
);
const AddBot = lazy(() =>
  import("./Components/BotManagement/Components/AddBot.jsx")
);
const BotDetails = lazy(() =>
  import("./Components/BotManagement/Components/BotDetails.jsx")
);
const ChangePassword = lazy(() =>
  import("./Components/Account/Components/ChangePassword.jsx")
);
const TaskDetails = lazy(() =>
  import("./Components/TaskDetails/Components/TaskDetails.jsx")
);
const Settings = lazy(() =>
  import("./Components/ManageUser/Components/Settings.jsx")
);
const ForgotPassword = lazy(() =>
  import("./Components/Account/Components/ForgotPassword.jsx")
);
const EditBotDetails = lazy(() =>
  import("./Components/BotManagement/Components/EditBotDetails.jsx")
);
const ManageUser = lazy(() =>
  import("./Components/ManageUser/Components/ManageUser.jsx")
);
const SelectedChannelList = lazy(() =>
  import("./Components/JoinChannels/Components/SelectedChannelList.jsx")
);
const JoinGroupList = lazy(() =>
  import("./Components/JoinGroups/Components/JoinGroupList.jsx")
);
const ClickLink = lazy(() =>
  import("./Components/ClickLink/Components/ClickLink.jsx")
);
const Leaderboard = lazy(() =>
  import("./Components/TaskDetails/Components/Leaderboard.jsx")
);
const Winner = lazy(() =>
  import("./Components/TaskDetails/Components/Winner.jsx")
);
const DrawEnded = lazy(() =>
  import("./Components/TaskDetails/Components/DrawEnded.jsx")
);
const Enter = lazy(() =>
  import("./Components/TaskDetails/Components/Enter.jsx")
);
const ProfileManagement = lazy(() =>
  import("./Components/ProfileManagement/Components/ProfileManagement.jsx")
);
const WebsiteConfiguration = lazy(() =>
  import("./Components/ManageUser/Components/WebsiteConfiguration.jsx")
);
const CreateAnnouncement = lazy(() =>
  import("./Components/AnnouncementManagement/Component/CreateAnnouncement.jsx")
);
const AnnouncementGroups = lazy(() =>
  import("./Components/AnnouncementManagement/Component/AnnouncementGroups.jsx")
);
const AnnouncementChannels = lazy(() =>
  import(
    "./Components/AnnouncementManagement/Component/AnnouncementChannels.jsx"
  )
);

const AnnouncementList = lazy(() =>
  import("./Components/AnnouncementManagement/Component/AnnouncementList.jsx")
);

const AnnouncementMessage = lazy(() =>
  import(
    "./Components/AnnouncementManagement/Component/AnnouncementMessage.jsx"
  )
);

const AnnouncementDetails = lazy(() =>
  import(
    "./Components/AnnouncementManagement/Component/AnnouncementDetails.jsx"
  )
);
const EditTask = lazy(() =>
  import("./Components/CustomTask/Component/EditTask.jsx")
);
const EntrantsUserList = lazy(() =>
  import("./Components/TaskDetails/Components/EntrantsUserList.jsx")
);
const CreatedTaskList = lazy(() =>
  import("./Components/ProfileManagement/Components/CreatedTaskList.jsx")
);
const UserDetailsComponent = lazy(() =>
  import("./Components/ManageUser/Components/UserDetailsComponent.jsx")
);
const ParticipatedTaskList = lazy(() =>
  import("./Components/ProfileManagement/Components/ParticipatedTaskList.jsx")
);
const ManageAdmin = lazy(() =>
  import("./Components/ManageAdmins/Components/ManageAdmin.jsx")
);
const AdminDetails = lazy(() =>
  import("./Components/ManageAdmins/Components/AdminDetails.jsx")
);
const SpecificRegion = lazy(() =>
  import("./Components/SpecificRegion/Components/SpecificRegion.jsx")
);
const Referrals = lazy(() =>
  import("./Components/Referal/Components/Referral.jsx")
);
const FacebookAdList = lazy(() =>
  import("./Components/FacebookAds/Component/FacebookAdList.jsx")
);
const FacebookAdDetails = lazy(() =>
  import("./Components/FacebookAds/Component/FacebookAdDetails.jsx")
);
const FacebookAd = lazy(() =>
  import("./Components/FacebookAds/Component/FacebookAd.jsx")
);
const AmountPending = lazy(() =>
  import("./Components/Payment/Components/AmountPending.jsx")
);
const PaymentSettings = lazy(() =>
  import("./Components/Payment/Components/PaymentSettings.jsx")
);
const UserWallet = lazy(() =>
  import("./Components/Userwallet/Components/UserWallet.jsx")
);

var start_param = tgStart_Param();
function App() {
  // const Declaration
  const [isLoading, setLoading] = useState(false);
  const user = UserDetails();
  const showAlert = useCustomAlert();
  const Bot = getQueryParam("BotId");
  const { t } = useTranslation();
  //hooks
  useEffect(() => {
    // Call the helper method to initialize and apply Telegram theme
    const initialize = async () => {
      try {
        setLoading(true);
        localStorage.removeItem("DefaultImage");
        // Call function to initialize Telegram theme
        if (Bot !== undefined && Bot !== null) {
          const bot = await decrypt(Bot);
          localStorage.setItem("BotId", bot);
        }
        const cleanup = initializeTelegramTheme();
        await CreateUserWhenEnteringinTask();
        // Fetch token and set it in cookies
        await GenerateToken();

        setLoading(false);
        // Cleanup event listeners on component unmount
        return cleanup;
      } catch (error) {
        console.error(error.message);
      }
    };

    initialize();
  }, []);
  //hooks ends here

  //Api function to get the Jwt token

  const GenerateToken = async () => {
    // await CreateUserWhenEnteringinTask();
    try {
      const url = `${process.env.REACT_APP_APiBaseAddress}/api/GenerateToken/GetToken`;
      if (user != undefined) {
        const response = await axios.get(url, {
          params: {
            UserId: await encrypt(String(user.id)),
          },
        });

        if (response.data.data) {
          const token = decodeURIComponent(response.data.data);
          Cookies.set("token", token, {
            expires: 1 / 24,
            path: "/",
            secure: true,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  async function getUserIP() {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching user IP:", error);
      return null;
    }
  }
  const CreateUserWhenEnteringinTask = async () => {
    if (user) {
      let botId;
      if (start_param) {
        const decodedData = decodeBase64(start_param);
        const url = new URLSearchParams(decodedData);
        const ReferBy = url.get("ReferBy");
        botId = url.get("BotId");
        if (ReferBy !== undefined && ReferBy !== null) {
          try {
            await AddReferalInvition(ReferBy);
          } catch (error) {
            console.error(error);
          }
        }
      } else {
        if (Bot !== undefined && Bot !== null) {
          botId = await decrypt(Bot);
        }
      }

      try {
        const Ip = await getUserIP();
        const response = await axios.post(CreateUser, {
          TGUserId: await encrypt(String(user.id)),
          BotId: await encrypt(botId),
          FirstName:
            user.first_name !== null && user.first_name !== undefined
              ? await encrypt(String(user.first_name))
              : "",
          LastName:
            user.last_name !== null && user.last_name !== undefined
              ? await encrypt(String(user.last_name))
              : "",
          Username:
            user.username !== null && user.username !== undefined
              ? await encrypt(String(user.username))
              : "",
          IpAddress: Ip !== null && Ip !== undefined ? await encrypt(Ip) : "",
        });
        if (response && response.status !== 200) {
          showAlert("", t("Something Went Wrong"), "error");
        }
      } catch (error) {
        showAlert("", t("Something Went Wrong"), "error");
      }
    }
  };

  const AddReferalInvition = async (ReferBy) => {
    const response = await Post(AddReferal, {
      InvitedBy: await encrypt(String(ReferBy)),
      InvitedUser:
        user != null && user != undefined ? await encrypt(String(user.id)) : "",
      BotId: await encrypt(localStorage.getItem("BotId")),
      TGUserId:
        user != null && user != undefined ? await encrypt(String(user.id)) : "",
    });
    if (response && response.statusCode !== 200) {
      showAlert("", t("Something Went Wrong"), "error");
    }
  };
  return (
    <>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <Router>
          {/* <TonConnectUIProvider
            manifestUrl="https://tgtelewin.online/tonconnect-manifest.json"
            actionsConfiguration={{
              twaReturnUrl: `${process.env.REACT_APP_StartApplink}`,
            }}
          > */}
          <Suspense fallback={<SplashScreen />}>
            <FacebookContextProvider>
              {/* <SocialMediaLinkProvider> */}
              <SpecificRegionProvider>
                <AnnouncementProvider>
                  <AnnouncementGroupProvider>
                    <AnnouncementGroupListProvider>
                      <AnnouncementChannelProvider>
                        <AnnouncementChannelListProvider>
                          <CustomFormProvider>
                            <ImageProvider>
                              <PrizeProvider>
                                <ChannelProvider>
                                  <ChannelListCardProvider>
                                    <GroupProvider>
                                      <GroupListCardProvider>
                                        <ImageProvider>
                                          <DrawRulesProvider>
                                            <ParticipantDeadlineProvider>
                                              <SelectedLinkProvider>
                                                <SignInProvider>
                                                  <SignUpProvider>
                                                    <Routes>
                                                      <Route
                                                        path="/"
                                                        element={<Home />}
                                                      />

                                                      {/* Routes which is only available to the superadmin */}
                                                      <Route
                                                        element={
                                                          <PrivateRoute
                                                            allowedRoles={[
                                                              "SuperAdmin",
                                                            ]}
                                                          />
                                                        }
                                                      >
                                                        <Route
                                                          path="/ManageAdmin"
                                                          element={
                                                            <ManageAdmin />
                                                          }
                                                        />
                                                        <Route
                                                          path="/SignUp"
                                                          element={<SignUp />}
                                                        />
                                                        <Route
                                                          path="/AddBot"
                                                          element={<AddBot />}
                                                        />
                                                        <Route
                                                          path="/BotList"
                                                          element={<BotList />}
                                                        />
                                                        {/* <Route
                                                            path="/BotNotFound"
                                                            element={
                                                              <BotNotFound />
                                                            }
                                                          /> */}
                                                        <Route
                                                          path="/BotDetails/:botAdminGuid"
                                                          element={
                                                            <BotDetails />
                                                          }
                                                        />
                                                        <Route
                                                          path="/EditBotDetails/:botAdminGuid"
                                                          element={
                                                            <EditBotDetails />
                                                          }
                                                        />
                                                        <Route
                                                          path="/AdminDetail"
                                                          element={
                                                            <AdminDetails />
                                                          }
                                                        />
                                                        <Route
                                                          path="/PaymentSettings"
                                                          element={
                                                            <PaymentSettings />
                                                          }
                                                        />
                                                        {/* <Route
                                                            path="/PriceReview"
                                                            element={
                                                              <PriceReview />
                                                            }
                                                          /> */}
                                                      </Route>

                                                      {/* Routes which is only available to the superadmin */}

                                                      {/* Routes which is only available to the superadmin and admins*/}
                                                      <Route
                                                        element={
                                                          <PrivateRoute
                                                            allowedRoles={[
                                                              "SuperAdmin",
                                                              "Admin",
                                                            ]}
                                                          />
                                                        }
                                                      >
                                                        <Route
                                                          path="/Settings"
                                                          element={<Settings />}
                                                        />

                                                        <Route
                                                          path="/ManageUser"
                                                          element={
                                                            <ManageUser />
                                                          }
                                                        />
                                                        <Route
                                                          path="/AnnouncementMessage"
                                                          element={
                                                            <AnnouncementMessage />
                                                          }
                                                        />
                                                        <Route
                                                          path="/AnnouncementList"
                                                          element={
                                                            <AnnouncementList />
                                                          }
                                                        />
                                                        <Route
                                                          path="/AnnouncementDetails"
                                                          element={
                                                            <AnnouncementDetails />
                                                          }
                                                        />
                                                        <Route
                                                          path="/AnnouncementGroups"
                                                          element={
                                                            <AnnouncementGroups />
                                                          }
                                                        />
                                                        <Route
                                                          path="/AnnouncementChannels"
                                                          element={
                                                            <AnnouncementChannels />
                                                          }
                                                        />
                                                        <Route
                                                          path="/CreateAnnouncement"
                                                          element={
                                                            <CreateAnnouncement />
                                                          }
                                                        />
                                                        <Route
                                                          path="/ProfileManagement"
                                                          element={
                                                            <ProfileManagement />
                                                          }
                                                        />
                                                        <Route
                                                          path="/UserDetailsComponent"
                                                          element={
                                                            <UserDetailsComponent />
                                                          }
                                                        />

                                                        <Route
                                                          path="/WebsiteConfiguration"
                                                          element={
                                                            <WebsiteConfiguration />
                                                          }
                                                        />
                                                      </Route>

                                                      {/* Routes which is only available to the superadmin and admins*/}
                                                      <Route
                                                        path="/Home"
                                                        element={<Home />}
                                                      />
                                                      <Route
                                                        path="/Enter"
                                                        element={<Enter />}
                                                      />
                                                      <Route
                                                        path="/DrawRules"
                                                        element={<DrawRules />}
                                                      />
                                                      <Route
                                                        path="/DrawEnded"
                                                        element={<DrawEnded />}
                                                      />

                                                      <Route
                                                        path="/SignIn"
                                                        element={<SignIn />}
                                                      />

                                                      <Route
                                                        path="/ChangePassword/:userId"
                                                        element={
                                                          <ChangePassword />
                                                        }
                                                      />

                                                      <Route
                                                        path="/CreateTask"
                                                        element={<CreateTask />}
                                                      />
                                                      <Route
                                                        path="/TaskDetails/:taskId" ///TaskDetails/:taskId
                                                        element={
                                                          <TaskDetails />
                                                        }
                                                      />
                                                      <Route
                                                        path="/Leaderboard/:taskId"
                                                        element={
                                                          <Leaderboard />
                                                        }
                                                      />

                                                      <Route
                                                        path="/Winner/:taskId"
                                                        element={<Winner />}
                                                      />
                                                      <Route
                                                        path="/SelectedChannelList"
                                                        element={
                                                          <SelectedChannelList />
                                                        }
                                                      />
                                                      <Route
                                                        path="/JoinGroupList"
                                                        element={
                                                          <JoinGroupList />
                                                        }
                                                      />
                                                      <Route
                                                        path="/ClickLink"
                                                        element={<ClickLink />}
                                                      />
                                                      <Route
                                                        path="/SelectLanguage"
                                                        element={
                                                          <SelectLanguage />
                                                        }
                                                      />

                                                      {/* <Route
                                                          path="/EditPassword"
                                                          element={
                                                            <EditPassword />
                                                          }
                                                        />

                                                        <Route
                                                          path="/EditUserName"
                                                          element={
                                                            <EditUserName />
                                                          }
                                                        /> */}

                                                      {/* <Route
                                                          path="/SuperAdminProfile"
                                                          element={
                                                            <SuperAdminProfile />
                                                          }
                                                        /> */}

                                                      <Route
                                                        path="/SuperAdminProfile"
                                                        element={
                                                          <SuperAdminProfile />
                                                        }
                                                      />

                                                      <Route
                                                        path="/ForgotPassword"
                                                        element={
                                                          <ForgotPassword />
                                                        }
                                                      />
                                                      <Route
                                                        path="/EditTaskDetails/:taskId"
                                                        element={<EditTask />}
                                                      />
                                                      <Route
                                                        path="/EntranceList/:taskId"
                                                        element={
                                                          <EntrantsUserList />
                                                        }
                                                      />
                                                      <Route
                                                        path="/CreatedList"
                                                        element={
                                                          <CreatedTaskList />
                                                        }
                                                      />
                                                      <Route
                                                        path="/Splash"
                                                        element={
                                                          <SplashScreen />
                                                        }
                                                      />
                                                      <Route
                                                        path="/SharingLinkPopUp"
                                                        element={
                                                          <SharingLinkPopUp />
                                                        }
                                                      />

                                                      <Route
                                                        path="/UserRestricted"
                                                        element={
                                                          <UserRestricted />
                                                        }
                                                      />
                                                      <Route
                                                        path="/ParticipatedList"
                                                        element={
                                                          <ParticipatedTaskList />
                                                        }
                                                      />
                                                      <Route
                                                        path="/SpecificRegion"
                                                        element={
                                                          <SpecificRegion />
                                                        }
                                                      />

                                                      <Route
                                                        path="/Referrals"
                                                        element={<Referrals />}
                                                      />
                                                      <Route
                                                        path="/FacebookAd"
                                                        element={<FacebookAd />}
                                                      />
                                                      {/* <Route
                                                        path="/AccountManagement"
                                                        element={
                                                          <AccountManagement />
                                                        }
                                                      /> */}
                                                      <Route
                                                        path="/FacebookAdList"
                                                        element={
                                                          <FacebookAdList />
                                                        }
                                                      />
                                                      <Route
                                                        path="/FacebookAdDetails"
                                                        element={
                                                          <FacebookAdDetails />
                                                        }
                                                      />
                                                      <Route
                                                        path="/AmountPending"
                                                        element={
                                                          <AmountPending />
                                                        }
                                                      />

                                                      <Route
                                                        path="/UserWallet"
                                                        element={<UserWallet />}
                                                      />
                                                    </Routes>
                                                  </SignUpProvider>
                                                </SignInProvider>
                                              </SelectedLinkProvider>
                                            </ParticipantDeadlineProvider>
                                          </DrawRulesProvider>
                                        </ImageProvider>
                                      </GroupListCardProvider>
                                    </GroupProvider>
                                  </ChannelListCardProvider>
                                </ChannelProvider>
                              </PrizeProvider>
                            </ImageProvider>
                          </CustomFormProvider>
                        </AnnouncementChannelListProvider>
                      </AnnouncementChannelProvider>
                    </AnnouncementGroupListProvider>
                  </AnnouncementGroupProvider>
                </AnnouncementProvider>
              </SpecificRegionProvider>
              {/* </SocialMediaLinkProvider> */}
            </FacebookContextProvider>
            {/* </TonConnectUIProvider> */}
          </Suspense>
        </Router>
      )}
    </>
  );
}

export default App;
