// import React, { createContext, useContext, useState } from 'react';
// import '../Components/User/Custom/Css/Custom.css';
// // Create context
// const GroupListCardContext = createContext();

// // Custom hook to access prize data and update functions
// export const useGroupListCardContext = () => useContext(GroupListCardContext);

// // Provider component to wrap your application
// export const GroupListCardProvider = ({ children }) => {
//   const [groups,setGroups] = useState([]);
//   return (
//     <GroupListCardContext.Provider value={{ groups,setGroups }}>
//       {children}
//     </GroupListCardContext.Provider>
//   );
// };


// <-------------------------------------******************************----------------------------------------->

// GroupListCardContext.js
import React, { createContext, useContext, useState } from 'react';

const GroupListCardContext = createContext();

export const useGroupListCardContext = () => useContext(GroupListCardContext);

export const GroupListCardProvider = ({ children }) => {
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const handleGroupSelection = (groupId, groupName, groupTgId) => {
    const index = selectedGroups.findIndex(group => group.id === groupId);
    if (index === -1) {
      setSelectedGroups(prevSelectedGroups => [...prevSelectedGroups, { id: groupId, name: groupName, groupId: groupTgId }]);
    } else {
      setSelectedGroups(prevSelectedGroups => prevSelectedGroups.filter(group => group.id !== groupId));
    }
  };

  return (
    <GroupListCardContext.Provider value={{ groups, setGroups, selectedGroups, handleGroupSelection ,setSelectedGroups}}>
      {children}
    </GroupListCardContext.Provider>
  );
};
