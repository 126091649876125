import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { UserDetails } from "../Helper/TgMiniAppFunctions";
import { getCurrentLocalDateTime } from "../Helper/DateTimeHelper";

// Create context
const CustomFormContext = createContext();

// Custom hook to access form data and update functions
export const useFormContext = () => useContext(CustomFormContext);
const currentUserDetails = UserDetails();

// Provider component to wrap your application
export const CustomFormProvider = ({ children }) => {
  const [selectedCustomTask, setSelectedCustomTask] = useState({
    Name: "",
    Prize: [],
    showAmountFields: true,
    selectedOption: "timed",
    dateTime: getCurrentLocalDateTime(),
    selectedChannels: [],
    selectedGroups: [],
    instructions: "",
    hostContact:
      currentUserDetails &&
      currentUserDetails.username !== undefined &&
      currentUserDetails.username !== null
        ? String(currentUserDetails.username)
        : "",
    DrawParticipants: 0,
    Id: 0,
  });

  const { t } = useTranslation();
  const [isGridView, setIsGridView] = useState(false);
  const [isShowCarouselChecked, setIsShowCarouselChecked] = useState(false);
  const [TouchedTitle, setTouchedTitle] = useState(false);
  const [TouchedHostContact, setTouchedHostContact] = useState(false);
  const [isBlockedUser, setIsBlockedUser] = useState(false);
  const [flag, setflag] = useState(false);

  const [QtyFlag, setQtyFlag] = useState(false);
  const [errors, setErrors] = useState({
    Name: "",
    hostContact: "",
    selectedOption: "",
    DrawParticipants: "",
  });

  const handleTitleTouch = () => {
    setTouchedTitle(true);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedCustomTask((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleHostContactTouch = () => {
    setTouchedHostContact(true);
  };

  // // Validation form
  // const validateForm = () => {
  //   let valid = true;
  //   const newErrors = {};

  //   // Check title is filled
  //   if (selectedCustomTask.Name.trim() === "" && TouchedTitle) {
  //     newErrors.Name = t("Title is required");
  //     valid = false;
  //   }

  //   // Check title is filled
  //   if (selectedCustomTask.hostContact.trim() === "" && TouchedHostContact) {
  //     newErrors.hostContact = t("This field is required");
  //     valid = false;
  //   }

  //   if (flag) {
  //     valid = false;
  //   }

  //   if (QtyFlag) {
  //     valid = false;
  //   }

  //   // Check Draw Type is filled
  //   if (selectedCustomTask.selectedOption.trim() === "") {
  //     newErrors.selectedOption = t("Draw Type is required");
  //     valid = false;
  //   }

  //   setErrors(newErrors);
  //   return valid;
  // };
  // Validation form
  const validateForm = useCallback(() => {
    let valid = true;
    const newErrors = {};

    // Check title is filled
    if (selectedCustomTask.Name.trim() === "" && TouchedTitle) {
      newErrors.Name = t("Title is required");
      valid = false;
    }

    // Check host contact is filled
    if (selectedCustomTask.hostContact.trim() === "" && TouchedHostContact) {
      newErrors.hostContact = t("This field is required");
      valid = false;
    }

    if (flag) {
      valid = false;
    }

    if (QtyFlag) {
      valid = false;
    }

    // Check Draw Type is filled
    if (selectedCustomTask.selectedOption.trim() === "") {
      newErrors.selectedOption = t("Draw Type is required");
      valid = false;
    }

    if (selectedCustomTask.selectedOption.trim() === "full") {
      if (
        selectedCustomTask.DrawParticipants === "" ||
        selectedCustomTask.DrawParticipants === 0
      ) {
        newErrors.DrawParticipants = t(
          "Draw Participants must be greater than 0"
        );
        valid = false;
      }
    }

    setErrors(newErrors);
    return valid;
  }, [selectedCustomTask, TouchedTitle, TouchedHostContact, flag, QtyFlag, t]);
  useEffect(() => {
    validateForm();
  }, [
    selectedCustomTask,
    TouchedTitle,
    TouchedHostContact,
    flag,
    validateForm,
  ]);

  return (
    <CustomFormContext.Provider
      value={{
        selectedCustomTask,
        setSelectedCustomTask,
        errors,
        validateForm,
        setTouchedHostContact,
        setTouchedTitle,
        handleTitleTouch,
        handleHostContactTouch,
        flag,
        setflag,
        QtyFlag,
        setQtyFlag,
        handleInputChange,
        isBlockedUser,
        setIsBlockedUser,
        isGridView,
        setIsGridView,
        isShowCarouselChecked,
        setIsShowCarouselChecked,
      }}
    >
      {children}
    </CustomFormContext.Provider>
  );
};
