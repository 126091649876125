import React, { createContext, useContext, useState } from 'react';

// Create a new context for participant deadline days
const ParticipantDeadlineContext = createContext();

// Custom hook to access participant deadline days context
export const useParticipantDeadline = () => useContext(ParticipantDeadlineContext);

// Component to provide participant deadline days
export const ParticipantDeadlineProvider = ({ children }) => {
  const [deadlineDays, setDeadlineDays] = useState(10); // Default value for the number of days

  const handleDaysChange = (value) => {
    setDeadlineDays(value);
  };

  return (
    <ParticipantDeadlineContext.Provider value={{ deadlineDays, setDeadlineDays, handleDaysChange }}>
      {children}
    </ParticipantDeadlineContext.Provider>
  );
};
