// // EncryptionHelper.js
const key = new Uint8Array([
  0x4a, 0xe3, 0x7f, 0x2c, 0x91, 0x6b, 0x5d, 0x3a, 0x8f, 0xc9, 0x10, 0x7e, 0x58,
  0xa2, 0x34, 0x9d, 0x20, 0x7c, 0x1e, 0x5a, 0x8b, 0xd3, 0xf6, 0x72, 0x45, 0xaf,
  0x61, 0xb9, 0xc5, 0x93, 0x08, 0x00,
]);

const saltSize = 16; // Size of the salt in bytes

function generateSalt() {
  return window.crypto.getRandomValues(new Uint8Array(saltSize));
}

async function importKey(rawKey) {
  return await window.crypto.subtle.importKey("raw", rawKey, "AES-CBC", false, [
    "encrypt",
    "decrypt",
  ]);
}

async function encrypt(plainText) {
  const salt = generateSalt();
  const iv = salt; // Use the salt as the IV
  const aesKey = await importKey(key);
  const encoder = new TextEncoder();
  const encoded = encoder.encode(plainText);

  const cipherText = await window.crypto.subtle.encrypt(
    { name: "AES-CBC", iv: iv },
    aesKey,
    encoded
  );

  const cipherArray = new Uint8Array(cipherText);
  const combinedArray = new Uint8Array(saltSize + cipherArray.length);
  combinedArray.set(salt);
  combinedArray.set(cipherArray, saltSize);

  return btoa(String.fromCharCode(...combinedArray));
}

async function decrypt(encryptedText) {
  try {
    const decodedText = decodeURIComponent(encryptedText);
    const cipherTextBytesWithSalt = new Uint8Array(
      atob(decodedText)
        .split("")
        .map((char) => char.charCodeAt(0))
    );
    const salt = cipherTextBytesWithSalt.slice(0, saltSize);
    const cipherTextBytes = cipherTextBytesWithSalt.slice(saltSize);

    const aesKey = await importKey(key);
    const iv = salt; // Use the salt as the IV

    const plainText = await window.crypto.subtle.decrypt(
      { name: "AES-CBC", iv: iv },
      aesKey,
      cipherTextBytes
    );

    const decoder = new TextDecoder();
    return decoder.decode(plainText);
  } catch (error) {
    console.error("Decryption error:", error);
    throw error;
  }
}

export { encrypt, decrypt };


// const key = new Uint8Array([
//   0x4a, 0xe3, 0x7f, 0x2c, 0x91, 0x6b, 0x5d, 0x3a, 0x8f, 0xc9, 0x10, 0x7e, 0x58,
//   0xa2, 0x34, 0x9d, 0x20, 0x7c, 0x1e, 0x5a, 0x8b, 0xd3, 0xf6, 0x72, 0x45, 0xaf,
//   0x61, 0xb9, 0xc5, 0x93, 0x08, 0x00,
// ]);

// const ivSize = 16; // Size of the IV in bytes
// const saltSize = 16; // Size of the salt in bytes

// function generateSalt() {
//   return window.crypto.getRandomValues(new Uint8Array(saltSize));
// }

// function generateIV() {
//   return window.crypto.getRandomValues(new Uint8Array(ivSize));
// }

// async function importKey(rawKey) {
//   return await window.crypto.subtle.importKey("raw", rawKey, "AES-CBC", false, [
//     "encrypt",
//     "decrypt",
//   ]);
// }

// function arrayBufferToBase64(buffer) {
//   let binary = '';
//   const bytes = new Uint8Array(buffer);
//   const len = bytes.byteLength;
//   for (let i = 0; i < len; i++) {
//     binary += String.fromCharCode(bytes[i]);
//   }
//   return btoa(binary);
// }

// function base64ToArrayBuffer(base64) {
//   const binaryString = atob(base64);
//   const len = binaryString.length;
//   const bytes = new Uint8Array(len);
//   for (let i = 0; i < len; i++) {
//     bytes[i] = binaryString.charCodeAt(i);
//   }
//   return bytes.buffer;
// }

// async function encrypt(plainText) {
//   const salt = generateSalt();
//   const iv = generateIV();
//   const aesKey = await importKey(key);
//   const encoder = new TextEncoder();
//   const encoded = encoder.encode(plainText);

//   const cipherText = await window.crypto.subtle.encrypt(
//     { name: "AES-CBC", iv: iv },
//     aesKey,
//     encoded
//   );

//   const combinedArray = new Uint8Array(
//     saltSize + ivSize + cipherText.byteLength
//   );
//   combinedArray.set(salt);
//   combinedArray.set(iv, saltSize);
//   combinedArray.set(new Uint8Array(cipherText), saltSize + ivSize);

//   console.log("Combined Array (Salt + IV + CipherText):", combinedArray);

//   return arrayBufferToBase64(combinedArray.buffer);
// }

// async function decrypt(encryptedText) {
//   try {
//     const cipherTextBytesWithSaltIV = new Uint8Array(
//       base64ToArrayBuffer(encryptedText)
//     );

//     console.log(
//       "Decoded Text Bytes with Salt and IV:",
//       cipherTextBytesWithSaltIV
//     );

//     const salt = cipherTextBytesWithSaltIV.slice(0, saltSize);
//     const iv = cipherTextBytesWithSaltIV.slice(saltSize, saltSize + ivSize);
//     const cipherTextBytes = cipherTextBytesWithSaltIV.slice(saltSize + ivSize);

//     console.log("Salt:", salt);
//     console.log("IV:", iv);
//     console.log("Cipher Text Bytes:", cipherTextBytes);

//     const aesKey = await importKey(key);

//     const plainText = await window.crypto.subtle.decrypt(
//       { name: "AES-CBC", iv: iv },
//       aesKey,
//       cipherTextBytes
//     );

//     const decoder = new TextDecoder();
//     return decoder.decode(plainText);
//   } catch (error) {
//     console.error("Decryption error:", error);
//     throw error;
//   }
// }

// export { encrypt, decrypt };
