import { DateTime } from "luxon";
export function getCurrentLocalDateTime() {
  const now = new Date();
  now.setDate(now.getDate() + 3); // Add 3 days

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export const formatDate = (dateString) => {
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
};

export const formatDateTime = (dateString) => {
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
};

// function FormattedDate(CreatedDate) {
//   const createdDate = new Date(CreatedDate);
//   const formattedDate = createdDate.toLocaleString("en-US", {
//     year: "numeric",
//     month: "2-digit",
//     day: "2-digit",
//     hour: "2-digit",
//     minute: "2-digit",
//     hour12: true,
//   });
//   return formattedDate;
// }
export const GetFormattedCurrentDateTimeForDatePicker = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(today.getDate()).padStart(2, "0");
  const hours = String(today.getHours()).padStart(2, "0");
  const minutes = String(today.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};
export const convertToHongKongTime = (date) => {
  const hongKongTimezone = "Asia/Hong_Kong";

  // Create a new Date object and convert it to Hong Kong time
  const hongKongDate = new Date(date).toLocaleString("en-US", {
    timeZone: hongKongTimezone,
  });

  return hongKongDate;
};
// export const convertFromHongKongToLocalTime = (hongKongDateTime) => {
//   // Automatically detect the user's timezone
//   const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

//   console.log("UserTImeZone", userTimeZone);

//   // Create a DateTime object in Hong Kong timezone
//   const hongKongDateTimeObj = DateTime.fromJSDate(
//     new Date(hongKongDateTime)
//   ).setZone("Asia/Hong_Kong");
//   console.log("HongKong", hongKongDateTimeObj);

//   // Convert to the user's local timezone
//   const localDateTime = hongKongDateTimeObj.setZone(userTimeZone);

//   return localDateTime;
// };
export const convertFromHongKongToLocalTime = (hongKongDateTime) => {
  // Automatically detect the user's timezone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Create a DateTime object in Hong Kong timezone (same as China Standard Time)
  const hongKongDateTimeObj = DateTime.fromISO(hongKongDateTime, {
    zone: "Asia/Hong_Kong",
  });

  // Convert to the user's local timezone
  const localDateTime = hongKongDateTimeObj.setZone(userTimeZone);

  return localDateTime;
};
