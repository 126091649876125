//Importing Hooks
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
//Importing Hooks Ends Here

//Importing Assests and Css
import themeBg from "../../../assets/images/theme-bg-dark.png";
import style from "../Css/CreateTask.module.css";
//Imporing Assests and Css ends here

//Importing Context
import { useFormContext } from "../../../Context/CustomFormContext";
import { useChannelListCardContext } from "../../../Context/ChannelListCardContext";
import { useGroupListCardContext } from "../../../Context/GroupListCardContext";
import { useGroupContext } from "../../../Context/GroupContext";
import { useChannelContext } from "../../../Context/ChannelContext";
import { useLinkContext } from "../../../Context/SelectedlinkContext";
import { usePrizeContext } from "../../../Context/PrizeContext";
import { useParticipantDeadline } from "../../../Context/ParticipantDeadlineContext ";
//Importing Context Ends Here

//Importing Helper Methods
import { BackButton, UserDetails } from "../../../Helper/TgMiniAppFunctions";
import {
  getCurrentLocalDateTime,
  GetFormattedCurrentDateTimeForDatePicker,
} from "../../../Helper/DateTimeHelper";
//Importing Helper Methods Ends here

//Importing Third Party Library

import Cookies from "js-cookie";
//Importing 3rd P Library Ends here

//Importing Common Enums or Common Methods

import { PrizeTypeEnum, TaskStatus } from "../../../Enums/Enum";
import { useCustomAlert } from "../../../Helper/Alerts";
//Importing Common things ends here

//Importing  the Components
import { PrizeTypeComponent } from "./PrizeTypeComponent";
import { useImageContext } from "../../../Context/ImageContext";
import ImageUploadComponent from "./ImageComponent";
import { SplashScreen } from "../../SplashScreen/Components/SplashScreen";
import CustomPopover from "../../../Helper/CustomPopover/Component/CustomPopover";
import DrawRules from "../../TaskDetails/Components/DrawRules";
import { Get, Post } from "../../../Helper/ApiServices";
import { decodeJwt } from "../../../Helper/Decoder";
import { useSpecificRegionContext } from "../../../Context/SpecificRegionContext";

import { encrypt } from "../../../Helper/EncryptionHelper";
import { GetCurrencies } from "../../../Helper/UrlConstant";
//Importing Components Ends here
//Global Const Declaration
const currentUserDetails = UserDetails();

export default function CreateTask() {
  const {
    selectedCustomTask,
    setSelectedCustomTask,
    errors,
    handleInputChange,
    validateForm,
    handleTitleTouch,
    handleHostContactTouch,
    setTouchedHostContact,
    setTouchedTitle,
    isGridView,
    setIsGridView,
    isShowCarouselChecked,
    setIsShowCarouselChecked,
  } = useFormContext();

  const { selectedGroupData, setSelectedgroupData, setGroups } =
    useGroupContext();
  const { setSelectedChannels } = useChannelListCardContext();
  const { setSelectedGroups } = useGroupListCardContext();
  const { selectedchannelData, setselectedChannelListData, setChannels } =
    useChannelContext();
  const { selectedlinkData, setselectedlinkData, setInputs } = useLinkContext();
  // const { selectedSocialMediaUrl, setselectedSocialMediaUrl } =
  //   useSocialMediaLinkContext();
  const { setPrizes, setTouchedAmount, setTouchedQty } = usePrizeContext();
  const { deadlineDays, handleDaysChange } = useParticipantDeadline();
  const { imageUrl, setImageUrl, setPreviewUrl } = useImageContext();
  const { checkedCountries, setCheckedCountries } = useSpecificRegionContext(
    []
  );
  //Context Ends here

  //Hooks comes here
  // const [isAdminChecked, setIsAdminChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formIsValid, setFormIsValid] = useState(true);
  const navigate = useNavigate();
  const showAlert = useCustomAlert();
  const [isChecked, setIsChecked] = useState(true);
  const [isShowPointBonuses, setIsShowPointBonuses] = useState(false);
  const { t } = useTranslation();
  const [adminDetails, setAdminDetails] = useState({
    Email: "",
    Username: "",
    Role: "",
    Id: "",
  });
  const [TotalInvite, setTotalInvite] = useState(10);
  const [points, setPoints] = useState(20);
  const [currencies, setCurrencies] = useState();

  useEffect(() => {
    // Initialize Bootstrap popover
    const initialize = async () => {
      // addHomeOptionToTelegramMenu();
      setIsLoading(true);
      const cleanup = BackButton(() => {
        ResetForm();
        window.history.back();
      });
      await Promise.all([await ValidateAdmins()]);
      setIsLoading(false);
      return cleanup;
    };
    initialize();
  }, []);

  //Const comes here for select mode
  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
  };

  // const handleAdminSwitchChange = (event) => {
  //   setIsAdminChecked(event.target.checked);
  // };

  const handleGridView = () => {
    if (isShowCarouselChecked) {
      setIsGridView(!isGridView);
      setIsShowCarouselChecked(!isShowCarouselChecked);
    } else if (!isShowCarouselChecked) {
      setIsGridView(!isGridView);
    }
  };

  const handleCaraouselView = () => {
    if (isGridView) {
      setIsShowCarouselChecked(!isShowCarouselChecked);
      setIsGridView(!isGridView);
    } else if (!isGridView) {
      setIsShowCarouselChecked(!isShowCarouselChecked);
    }
  };

  //Context comes Here

  //Functions

  function ResetForm() {
    let Image = localStorage.getItem("DefaultImage");
    if (Image !== null && Image !== undefined) {
      setImageUrl(Image);
    } else {
      setImageUrl(
        "https://img.freepik.com/free-vector/realistic-shopping-banner-design_23-2150277680.jpg?w=826&t=st=1716544980~exp=1716545580~hmac=59d340918106b4421c0d77bac090214cd4f42720f88d1dced2b540ff533b1f21"
      );
    }
    setPreviewUrl(null);
    setSelectedCustomTask((prevCustomTask) => ({
      ...prevCustomTask,
      Name: "",
      selectedOption: "timed",
      dateTime: getCurrentLocalDateTime(),
      DrawParticipants: 0,
      instructions: "",
      selectedGroups: [],
      selectedChannels: [],
      hostContact:
        currentUserDetails.username != null &&
        currentUserDetails.username !== undefined &&
        currentUserDetails.username !== "undefined"
          ? currentUserDetails.username
          : "",
      // !!currentUserDetails.username ? currentUserDetails.username : ""
    }));
    setPrizes([
      {
        id: 1,
        PrizeType: "Amount",
        CurrencyType: "",
        Amount: 0,
        PrizeName: "",
        Quantity: 0,
      },
    ]);
    setTouchedHostContact(false);
    setTouchedTitle(false);
    setTouchedAmount(false);
    setTouchedQty(false);
    setselectedChannelListData(null);
    setSelectedgroupData(null);

    setselectedlinkData(null);
    // setselectedSocialMediaUrl(null);
    setSelectedChannels([]);
    setSelectedGroups([]);
    setCheckedCountries([]);
    setIsShowCarouselChecked(false);
    setIsGridView(false);
  }

  const conditions = () => {
    let allconditions = "";
    if (selectedGroupData !== undefined && selectedGroupData !== null) {
      if (selectedGroupData.length > 0) {
        allconditions += allconditions === "" ? "Group" : "Group ,";
      }
    }
    if (selectedchannelData !== undefined && selectedchannelData !== null) {
      if (selectedchannelData.length > 0) {
        allconditions += allconditions === "" ? "Channel" : ", Channel";
      }
    }
    if (selectedlinkData !== undefined && selectedlinkData !== null) {
      if (selectedlinkData.length > 0) {
        allconditions += allconditions === "" ? "Link" : "Link ,";
      }
    }
    return allconditions;
  };

  function showCustomalert(message, Type) {
    showAlert("Error", message, Type, true, "Got it");
  }

  const combineTaskConditionList = () => {
    const channels =
      selectedchannelData !== undefined && selectedchannelData !== null
        ? selectedchannelData.map((channel) => ({
            ChannelId: channel.channelId,
            ChannelUserName: channel.name,
            GroupId: null,
            GroupUserName: null,
            Link: null,
          }))
        : selectedchannelData;

    const groups =
      selectedGroupData !== undefined && selectedGroupData !== null
        ? selectedGroupData.map((group) => ({
            ChannelId: null,
            ChannelUserName: null,
            GroupId: group.groupId,
            GroupUserName: group.name,
            Link: null,
          }))
        : selectedGroupData;

    const links =
      selectedlinkData !== undefined && selectedlinkData !== null
        ? selectedlinkData.map((link) => ({
            ChannelId: null,
            ChannelUserName: null,
            GroupId: null,
            GroupUserName: null,
            Link: link.value,
          }))
        : selectedlinkData;

    // Combine the arrays or return the individual non-array values
    const result = [];
    if (Array.isArray(channels)) result.push(...channels);
    else if (channels !== undefined && channels !== null) result.push(channels);

    if (Array.isArray(groups)) result.push(...groups);
    else if (groups !== undefined && groups !== null) result.push(groups);

    if (Array.isArray(links)) result.push(...links);
    else if (links !== undefined && links !== null) result.push(links);

    return result;
  };
  const userSpecificRegionList = () => {
    const regions =
      checkedCountries !== undefined && checkedCountries !== null
        ? checkedCountries.map((country) => ({
            CountryCode: country.value,
            CountryName: country.label,
          }))
        : checkedCountries;
    return regions;
  };
  const customPrizeList = () => {
    const prize = selectedCustomTask?.Prize.map((p) => ({
      PrizeType: PrizeTypeEnum[p.PrizeType],
      Currency: p.CurrencyType,
      Amount: p.Amount,
      PrizeName: p.PrizeName,
      Quantity: p.Quantity,
    }));
    return prize;
  };
  const hasAmountPrize = (customPrizeList, IsPrizeListFromApi) => {
    let hasAmount;
    if (IsPrizeListFromApi) {
      hasAmount = customPrizeList.some(
        (prize) => prize.prizeType === PrizeTypeEnum.Amount
      );
    } else {
      hasAmount = customPrizeList.some(
        (prize) => prize.PrizeType === PrizeTypeEnum.Amount
      );
    }

    return hasAmount;
  };
  //Functions End here

  //Onclick or Input Functions Comes here
  const isDateTimeValid = (selectedDateTime) => {
    const currentDateTime = new Date();
    const selectedDate = new Date(selectedDateTime);
    return selectedDate >= currentDateTime;
  };
  const handleDateTimeChange = (event) => {
    if (isDateTimeValid(event.target.value)) {
      setSelectedCustomTask((prevData) => ({
        ...prevData,
        dateTime: event.target.value,
      }));
    } else {
      showAlert("", t("Selected date and time cannot be in the past."), "info");
    }
  };

  const handleLinkOnclick = () => {
    navigate("/ClickLink");
  };

  const onGroupJoinClick = () => {
    setSelectedCustomTask((prevData) => ({
      ...prevData,
      selectedGroups: [],
    }));
    navigate("/JoinGroupList");
  };

  const onChannelJoinClick = () => {
    setSelectedCustomTask((prevData) => ({
      ...prevData,
      selectedChannels: [],
    }));
    navigate("/SelectedChannelList");
  };

  const handleParticipantDeadlineChange = (e) => {
    const value = parseInt(e.target.value);
    handleDaysChange(value);
  };

  const handleOptionChange = (event) => {
    setSelectedCustomTask((prevData) => ({
      ...prevData,
      selectedOption: event.target.value,
    }));
  };

  const handleDataFromPrizeComponent = (data) => {
    setSelectedCustomTask((prevData) => ({
      ...prevData,
      Prize: data,
    }));
  };
  const handleSubmitClick = async () => {
    setFormIsValid(true);
    handleTitleTouch();
    handleHostContactTouch();
    setTouchedAmount(true);
    setTouchedQty(true);
    if (
      !selectedCustomTask.hostContact ||
      selectedCustomTask.hostContact === ""
    ) {
      // If host contact is empty, set default value
      setSelectedCustomTask((prevData) => ({
        ...prevData,
        hostContact: String(
          currentUserDetails.username &&
            currentUserDetails.username != undefined
            ? currentUserDetails.username
            : ""
        ),
      }));
    }

    if (validateForm()) {
      //Validating the LatestDrawDate
      if (!isDateTimeValid(selectedCustomTask.dateTime)) {
        showAlert(
          "",
          t("Selected date and time cannot be in the past."),
          "info"
        );
        return;
      }

      const customTaskData = {
        Title: selectedCustomTask.Name,
        IsTimedDraw: selectedCustomTask.selectedOption === "timed",
        IsDrawWhenFull: selectedCustomTask.selectedOption === "full",
        LatestDrawDate: selectedCustomTask.dateTime,
        DrawParticipants:
          selectedCustomTask.selectedOption === "full"
            ? selectedCustomTask.DrawParticipants
            : 0,
        Instructions: selectedCustomTask.instructions,
        Conditions: conditions(),
        IsJoinedGroup:
          selectedGroupData === undefined || selectedGroupData === null
            ? false
            : selectedGroupData.length > 0,
        IsJoinedChannel:
          selectedchannelData === undefined || selectedchannelData === null
            ? false
            : selectedchannelData.length > 0,
        IsClicktheLink:
          selectedlinkData === undefined || selectedlinkData === null
            ? false
            : selectedlinkData.length > 0,
        NoOfGroups:
          selectedGroupData !== undefined && selectedGroupData !== null
            ? selectedGroupData.length
            : 0,
        NoOfChannels:
          selectedchannelData !== undefined && selectedchannelData !== null
            ? selectedchannelData.length
            : 0,
        NoOfLinks:
          selectedlinkData !== undefined && selectedlinkData !== null
            ? selectedlinkData.length
            : 0,
        PrizeContact: selectedCustomTask.hostContact,
        CreateByUserId: await encrypt(String(currentUserDetails.id)),
        CreateByUserName:
          currentUserDetails.username !== undefined &&
          currentUserDetails.username !== null
            ? String(currentUserDetails.username)
            : "",
        PrizeClaimDeadlineDays: deadlineDays,
        CustomPrizeList: selectedCustomTask.Prize.map((p) => ({
          PrizeType: PrizeTypeEnum[p.PrizeType],
          Currency: p.CurrencyType,
          Amount: p.Amount,
          PrizeName: p.PrizeName,
          Quantity: p.Quantity,
        })),
        CustomImage: imageUrl,
        CustomTaskConditionList: combineTaskConditionList(),
        // PostedbyAdmin: isAdminChecked,
        ShowinCarousel: isShowCarouselChecked,
        ShowinDrawer: isGridView,
        BotId: await encrypt(localStorage.getItem("BotId")),
        UserSpecificRegionList: userSpecificRegionList(),
        // SocialMediaUrlList: socialMediaUrlList(),
        IsShowPoints: isShowPointBonuses,
        NoofInvites: TotalInvite,
        NoofPointPerNoOfInvite: points,
        Status: hasAmountPrize(customPrizeList(), false)
          ? TaskStatus.Pending
          : TaskStatus.Awaiting,
        UserTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      const val = async () => {
        let isValid = true;
        if (!selectedCustomTask.Name) {
          setFormIsValid(false);
          isValid = false;
        }

        if (!selectedCustomTask.hostContact) {
          setFormIsValid(false);
          isValid = false;
        }

        selectedCustomTask.Prize.forEach((p) => {
          if (p.Quantity <= 0) {
            setFormIsValid(false);
            isValid = false;
          }
          //Added currency validation
          if (p.PrizeType === "Amount" && p.Amount <= 0) {
            setFormIsValid(false);
            isValid = false;
          } else if (p.PrizeType === "Amount" && p.CurrencyType === "") {
            //setFormIsValid(false);
            isValid = false;
            showAlert("", t("Please Select Valid Currency"), "info");
          } else if (p.PrizeType === "Gift" && p.PrizeName === "") {
            setFormIsValid(false);
            isValid = false;
          }
        });

        const updatedPrizes = selectedCustomTask.Prize.map((p) => ({
          id: p.id,
          PrizeType: p.PrizeType,
          CurrencyType: p.CurrencyType,
          Amount: p.Amount,
          PrizeName: p.PrizeName,
          Quantity: p.Quantity,
          amountTouched: true,
          qtyTouched: true,
          prizeNameTouched: true,
        }));
        setPrizes(updatedPrizes);
        if (isValid) {
          try {
            setIsLoading(true);
            const response = await Post(
              `${process.env.REACT_APP_APiBaseAddress}/api/CustomTask/CreateCustomTask`,
              customTaskData
            );
            setIsLoading(false);
            if (response.msg.includes("Successfully") && response.data) {
              //If the Task is Amount type and the payment is still pending then redirect to amount pending else into the task details
              if (
                hasAmountPrize(response.data.customPrizeList, true) &&
                response.data.status === TaskStatus.Pending
              ) {
                navigate(`/AmountPending`, {
                  state: { CustomTaskData: response.data },
                });
                return;
              } else {
                showCustomalert(t("Task Created"), "success");
                ResetForm();
                navigate(
                  `/TaskDetails/${encodeURIComponent(
                    await encrypt(response.data.id)
                  )}`
                );
              }
            } else if (response.statusCode !== 200) {
              showAlert("", t("Something Went Wrong"), "error");
            } else {
              showCustomalert(t("Task Creation Failed"), "error");
            }
          } catch (error) {
            showCustomalert(t("Task Creation Failed"), "error");
            console.error(
              t("There was an error creating the custom task!"),
              error
            );
          } finally {
            setIsLoading(false);
          }
        } else {
          validateForm();
          showCustomalert(t("Please Enter Mandatory Fields"), "error");
        }
      };

      // Call the val function here
      val();
    } else {
      showCustomalert(t("Please Enter Mandatory Fields"), "error");
    }
  };

  const ValidateAdmins = async () => {
    const token = Cookies.get("qEWbpONT");
    if (token !== null && token !== undefined) {
      const decodedtoken = decodeJwt(token);
      if (decodedtoken !== null && decodedtoken !== undefined) {
        setAdminDetails({
          Email: decodedtoken.email,
          Username: decodedtoken.unique_name,
          Role: decodedtoken[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
          ],
          Id: decodedtoken.UniqueId,
        });
      }
    }
  };

  const handleNavigateToSpecificRegion = async () => {
    navigate("/SpecificRegion");
  };

  const handleTotalInvites = (event) => {
    setTotalInvite(event.target.value);
    setPoints(event.target.value * 2);
  };

  const handlePointBonusestoggle = (event) => {
    setIsShowPointBonuses(event.target.checked);
    if (event.target.checked === false) {
      setTotalInvite(10);
      setPoints(20);
    }
  };

  const FetchCurrencies = async () => {
    const response = await Get(GetCurrencies, {
      TGUserId:
        currentUserDetails !== null && currentUserDetails !== undefined
          ? await encrypt(String(currentUserDetails.id))
          : "",
      BotId: await encrypt(localStorage.getItem("BotId")),
    });
    if (response && response.data) {
      setCurrencies(response.data);
    } else if (response && response.statusCode === 200) {
      showAlert("", t("Failed To Fetch Currencies"), "error");
    } else {
      showAlert("", t("Something Went Wrong"), "error");
    }
  };
  // const points = TotalInvite * 2;

  //Fetch the Default Imagr

  return (
    <div>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <div
          id="main-content"
          className="main-content"
          // style={{ overflowY: "auto", maxHeight: "100vh" }}
        >
          <div
            className="bg-image"
            style={{ backgroundImage: `url(${themeBg})` }}
          ></div>
          <div className={style["create-profile"]}>
            <div className="container-fluid">
              <div className="header-toolbar d-flex align-items-center mb-2">
                <div className="toolbar-center text-center flex-grow-1">
                  {t("Create Task")}
                </div>
                <div className="user-setting">
                  <a className="setting-btn"></a>
                </div>
              </div>
              <div className="create-top">
                <DrawRules />
              </div>

              <div className="box-with-form">
                <div className="form-group input-single">
                  <label>{t("Title")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Enter Title")}
                    name="Name"
                    value={selectedCustomTask.Name || ""}
                    onChange={handleInputChange}
                    onBlur={handleTitleTouch}
                    onClick={handleTitleTouch}
                  />
                  {errors.Name && (
                    <small className="text-danger">{errors.Name}</small>
                  )}
                </div>
                <PrizeTypeComponent
                  onData={handleDataFromPrizeComponent}
                  currencies={currencies}
                />

                <ImageUploadComponent />

                <div className="box-with-form">
                  <div className="d-flex">
                    <div className="col-6">
                      <div className="form-group  input-single">
                        <label>{t("Select Date")}</label>
                        <input
                          type="datetime-local"
                          id="dateTime"
                          name="dateTime"
                          className="form-control"
                          min={GetFormattedCurrentDateTimeForDatePicker()}
                          value={selectedCustomTask.dateTime}
                          onChange={handleDateTimeChange}
                        />
                      </div>
                    </div>
                    <div className="col-6 ">
                      <div className="form-group ms-1 mt-1 input-single">
                        <label></label>
                        <select
                          name=""
                          id=""
                          className="form-control"
                          onChange={handleOptionChange}
                        >
                          <option value="timed">{t("Timed Draw")}</option>
                          <option value="full">{t("Draw When Full")}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {selectedCustomTask.selectedOption === "full" && (
                    <>
                      <div className="form-group input-single ">
                        <label>{t("Participants")}</label>
                        <input
                          type="number"
                          className="form-control"
                          min={1}
                          name="DrawParticipants"
                          value={selectedCustomTask.DrawParticipants}
                          onChange={(e) => handleInputChange(e)}
                        />
                        {errors.DrawParticipants && (
                          <small className="text-danger">
                            {errors.DrawParticipants}
                          </small>
                        )}
                      </div>
                    </>
                  )}

                  <div className="form-group input-single">
                    <label>{t("Instructions")}</label>
                    <textarea
                      className="form-control"
                      name="instructions"
                      placeholder={t("Optional")}
                      id="floatingTextarea2"
                      style={{ height: "120 px" }}
                      value={selectedCustomTask.instructions || ""}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>

                  <div className="form-group input-single">
                    <label>{t("Task Mode")}</label>
                    <div className="d-flex">
                      <div className="col-4">
                        <a
                          className="btn m-1 btn-primary d-block rounded-3"
                          onClick={onGroupJoinClick}
                        >
                          {selectedGroupData !== undefined &&
                          selectedGroupData !== null
                            ? selectedGroupData.length > 1
                              ? `${selectedGroupData.length} ${t(
                                  "groups selected"
                                )}`
                              : selectedGroupData.length === 1
                              ? `${t("Join")} ${
                                  selectedGroupData[0].name.length > 7
                                    ? selectedGroupData[0].name.substring(0, 6)
                                    : selectedGroupData[0].name
                                }`
                              : `${t("Join Group")}`
                            : `${t("Join Group")}`}
                        </a>
                      </div>
                      <div className="col-4 ">
                        <a
                          className="btn m-1 btn-primary d-block rounded-3"
                          onClick={onChannelJoinClick}
                        >
                          {selectedchannelData !== undefined &&
                          selectedchannelData !== null
                            ? selectedchannelData.length > 1
                              ? `${selectedchannelData.length} ${t(
                                  "channels selected"
                                )}`
                              : selectedchannelData.length === 1
                              ? `${t("Join")} ${
                                  selectedchannelData[0].name.length > 7
                                    ? selectedchannelData[0].name.substring(
                                        0,
                                        6
                                      )
                                    : selectedchannelData[0].name
                                }`
                              : `${t("Join Channel")}`
                            : `${t("Join Channel")}`}
                        </a>
                      </div>
                      <div className="col-4 ">
                        <a
                          className="btn m-1 btn-primary d-block rounded-3"
                          onClick={handleLinkOnclick}
                        >
                          {selectedlinkData !== undefined &&
                          selectedlinkData !== null &&
                          selectedlinkData.length > 0
                            ? `${selectedlinkData.length} ${t("Link selected")}`
                            : `${t("Click Link")}`}
                        </a>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="col-12">
                        <a
                          className="btn m-1 btn-primary d-block rounded-3"
                          onClick={() => handleNavigateToSpecificRegion()}
                        >
                          {checkedCountries !== undefined &&
                          checkedCountries !== null
                            ? checkedCountries.length > 1
                              ? `${checkedCountries.length} ${t(" selected")}`
                              : checkedCountries.length === 1
                              ? `${t("Selected")} ${checkedCountries[0].label}`
                              : `${t("Select Region")}`
                            : `${t("Select Region")}`}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="form-group input-single">
                    <label>{t("Host Contact")}</label>
                    <input
                      type="text"
                      name="hostContact"
                      className="form-control"
                      placeholder={t("Enter Username of the host")}
                      value={selectedCustomTask.hostContact || ""}
                      onChange={handleInputChange}
                      onClick={handleHostContactTouch}
                      onBlur={handleHostContactTouch}
                    />
                    {errors.hostContact && (
                      <span className="text-danger">{errors.hostContact}</span>
                    )}
                  </div>

                  <div className="form-group input-single">
                    <label
                      data-bs-container="body"
                      data-bs-toggle="popover"
                      data-bs-placement="right"
                      data-bs-content="Right popover"
                    >
                      <CustomPopover
                        content={
                          <div>
                            {t(
                              "The Time limit for users to claim the prize after the draw , after which users can no longer contact you"
                            )}
                          </div>
                        }
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM7.25 4.75C7.25 4.3375 7.5875 4 8 4C8.4125 4 8.75 4.33594 8.75 4.75V8.75C8.75 9.16406 8.41406 9.5 8 9.5C7.58594 9.5 7.25 9.16562 7.25 8.75V4.75ZM8 12.5C7.4575 12.5 7.0175 12.06 7.0175 11.5175C7.0175 10.975 7.45719 10.535 8 10.535C8.54281 10.535 8.9825 10.975 8.9825 11.5175C8.98125 12.0594 8.54375 12.5 8 12.5Z"
                            fill="#34353C"
                          />
                        </svg>
                      </CustomPopover>
                      {t("Participant Deadline(in days)")}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      value={deadlineDays}
                      onChange={handleParticipantDeadlineChange}
                      min="1"
                      max="30"
                    />
                  </div>

                  {adminDetails &&
                    (adminDetails.Role === "SuperAdmin" ||
                      adminDetails.Role === "Admin") && (
                      <>
                        <div className="form-group input-single">
                          <label>{t("Select Mode")}</label>
                          <div className="switch-box">
                            <div className="form-check form-switch d-flex justify-content-between">
                              <label
                                className="form-check-label text-secondary"
                                htmlFor="flexSwitchCheckChecked"
                              >
                                {t("Show In Home page")} <br />
                                <span>({t("Grid View")})</span>
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckChecked"
                                checked={isGridView}
                                onChange={handleGridView}
                              />
                            </div>
                            <div className="form-check form-switch d-flex justify-content-between">
                              <label
                                className="form-check-label text-secondary"
                                htmlFor="flexSwitchCheckChecked"
                              >
                                {t("Show In Home page")} <br />{" "}
                                <span>({t("Carousel View")})</span>
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckChecked"
                                checked={isShowCarouselChecked}
                                onChange={handleCaraouselView}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group input-single">
                          <label>
                            <CustomPopover
                              content={
                                <div>
                                  {t(
                                    "Enable this functionality for the user to earn points based on the number of invites."
                                  )}
                                </div>
                              }
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM7.25 4.75C7.25 4.3375 7.5875 4 8 4C8.4125 4 8.75 4.33594 8.75 4.75V8.75C8.75 9.16406 8.41406 9.5 8 9.5C7.58594 9.5 7.25 9.16562 7.25 8.75V4.75ZM8 12.5C7.4575 12.5 7.0175 12.06 7.0175 11.5175C7.0175 10.975 7.45719 10.535 8 10.535C8.54281 10.535 8.9825 10.975 8.9825 11.5175C8.98125 12.0594 8.54375 12.5 8 12.5Z"
                                  fill="#34353C"
                                />
                              </svg>
                            </CustomPopover>
                          </label>
                          <label>{t("Point Bonuses for Invites")}</label>
                          <div className="switch-box">
                            <div className="form-check form-switch d-flex justify-content-between">
                              <label
                                className="form-check-label text-secondary"
                                htmlFor="flexSwitchCheckChecked"
                              >
                                {t("Enable Point Bonuses")} <br />
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckChecked"
                                checked={isShowPointBonuses}
                                onChange={handlePointBonusestoggle}
                              />
                            </div>
                          </div>
                        </div>
                        {isShowPointBonuses && (
                          <div className="d-flex">
                            <div className="col-6 form-group ms-1 mt-1 input-single">
                              <label>{t("No of Invites")}</label>
                              <select
                                name=""
                                id=""
                                className="form-control "
                                onChange={handleTotalInvites}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                              </select>
                            </div>

                            <div className=" col-6 form-group  ms-1 mt-1 input-single">
                              <label>{t("Points")}</label>
                              <input
                                type="text"
                                name="hostContact"
                                className="form-control"
                                placeholder=""
                                value={points}
                                readOnly
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                </div>
                <div className="form-actions-button pb-4">
                  <a
                    className="btn btn-primary w-100"
                    onClick={handleSubmitClick}
                  >
                    {t("Submit")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
