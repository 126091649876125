import React, { useState, createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Create a new context for draw rules
const DrawRulesContext = createContext();

// Custom hook to access draw rules context
export const useDrawRules = () => useContext(DrawRulesContext);

// Component to provide draw rules
export const DrawRulesProvider = ({ children }) => {
  const { t } = useTranslation();
  const [drawRules, setDrawRules] = useState([
    {
      id: 1,
      title: t('Draw Rules'),
      rules: [
        t("Starting with the first prize, randomly draw the winners for each award."),
        t("Each participant is limited to winning only once."),
        t("After activating the invitation feature, inviting others to participate can increase the chances of winning.")
      ]
    }
  ]);

  return (
    <DrawRulesContext.Provider value={{ drawRules, setDrawRules }}>
      {children}
    </DrawRulesContext.Provider>
  );
};
