import React from "react";
import logo from "../../../assets/images/logo-old.png";
import darkthemeBg from "../../../assets/images/theme-bg-dark.png";
import lightthemeBg from "../../../assets/images/theme-bg.png";

export const SplashScreen = () => {
  return (
    <div>
      <div id="main-content" className="main-content">
        <div
          className="bg-image splash-bg"
          style={{
            backgroundImage:
              localStorage.getItem("darkMode") === "enabled"
                ? `url(${darkthemeBg})`
                : `url(${lightthemeBg})`,
          }}
        ></div>
        <div className="splash">
          <div className="logo">
            <a>
              <img src={logo} alt="" />
            </a>
            <div className="d-flex justify-content-center align-items-center mt-5">
              <div
                className="spinner-border"
                style={{
                  width: "3rem",
                  height: "3rem",
                  color:
                    localStorage.getItem("darkMode") === "enabled"
                      ? "#b00009"
                      : "#86b7fe",
                }}
                role="status"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
