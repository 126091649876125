import { jwtDecode } from "jwt-decode";
// Helper function to decode Base64
export function decodeBase64(encodedStr) {
  try {
    const decodedStr = atob(encodedStr);
    return decodedStr;
  } catch (error) {
    console.error("Failed to decode Base64 string:", error);
    return null;
  }
}

// Function to encode a string to Base64
export function encodeBase64(str) {
  try {
    const encodedStr = btoa(str);
    return encodedStr;
  } catch (error) {
    console.error("Failed to encode string to Base64:", error);
    return null;
  }
}

export function decodeJwt(token) {
  const decoded = jwtDecode(token);
  return decoded;
}
