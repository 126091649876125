import React, { useContext, useEffect, useState } from "react";
import style from "../Css/SelectLanguage.module.css";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../../Context/LanguageContext";
import { useCustomAlert } from "../../../Helper/Alerts";
import { BackButton } from "../../../Helper/TgMiniAppFunctions";
import themeBg from "../../../assets/images/theme-bg-dark.png";
import { useTranslation } from "react-i18next";
function SelectLanguage() {
  // ***************hooks starts here********
  // const [selected, setSelected] = useState(null);
  const navigate = useNavigate();
  const showAlert = useCustomAlert();
  const { changeLanguage, selected, setSelected } = useContext(LanguageContext);
  const { t } = useTranslation();
  useEffect(() => {
    const cleanup = BackButton(() => {
      window.history.back();
    });
    return cleanup;
  }, []);
  // ***************hooks ends here ***************

  const handleCheckboxChange = (id) => {
    setSelected(id);
  };

  // Function to handle confirm button click
  const handleConfirmClick = () => {
    if (selected) {
      // console.log("language ", selected);
      changeLanguage(selected);
      navigate("/SuperAdminProfile");
    } else {
      showAlert(
        "Error",
        t("Please select a language"),
        "error",
        true,
        "Got it"
      );
    }
  };

  return (
    <div>
      <div>
        <div id="main-content" className="main-content">
          <div
            className="bg-image"
            style={{ backgroundImage: `url(${themeBg})` }}
          ></div>
          <div className="super-admin-profile">
            <div className="container-fluid">
              <div className="header-toolbar d-flex align-items-center mb-2">
                {/* <div className="back-button">
                  <a
                    href="#"
                    className="btn btn-link"
                    onClick={() => navigate(-1)}
                  >
                    <i className="bi bi-chevron-left"></i>
                  </a>
                </div> */}
                <div className="toolbar-center text-center flex-grow-1">
                  {t("Select Language")}
                </div>
                <div className="user-setting">
                  <a className="setting-btn primary-color-text"></a>
                </div>
              </div>

              <div className={`${style["admin-menu-sec"]} ${style["pb-100"]}`}>
                <ul>
                  {[
                    { id: "en-US", label: "English" },
                    { id: "es", label: "Spanish" },
                    { id: "ch", label: "Chinese" },
                    { id: "chl", label: "Chinese Literal" },
                    { id: "pu", label: "Portuguese" },
                    { id: "pub", label: "Portuguese (Brazil)" },
                  ].map((item) => (
                    <li key={item.id}>
                      <div
                        className={`d-flex justify-content-between  ${style["checkbox-list-item"]} align-content-center`}
                      >
                        <span className={`${style["text"]}`}>{item.label}</span>
                        <div
                          className={`${style["form-check"]} link-remember-me`}
                        >
                          <input
                            className="form-check-input"
                            name="language"
                            type="checkbox"
                            checked={selected === item.id}
                            onChange={() => handleCheckboxChange(item.id)}
                            id={`flexCheckDefault${item.id}`}
                          />
                          <label
                            className={`${style["form-check-label"]}`}
                            htmlFor={`flexCheckDefault${item.id}`}
                          ></label>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="form-actions-button">
                  <button
                    className="btn btn-primary w-100"
                    onClick={handleConfirmClick}
                  >
                    {t("Confirm")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectLanguage;
