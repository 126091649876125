import React, { useState, useRef, useEffect } from "react";
import '../Css/CustomPopover.css'

const CustomPopover = ({ content, children }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const popoverRef = useRef();

  const togglePopover = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setIsPopoverVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="custom-popover-wrapper" ref={popoverRef}>
      <div onClick={togglePopover}>{children}</div>
      {isPopoverVisible && (
        <div className="custom-popover-content">{content}</div>
      )}
    </div>
  );
};

export default CustomPopover;
