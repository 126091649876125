import React, { createContext, useContext, useState } from "react";
const AnnouncementChannelContext = createContext();

export const useAnnouncementChannelContext = () =>
  useContext(AnnouncementChannelContext);

// Provider component to wrap your application
export const AnnouncementChannelProvider = ({ children }) => {
  const [selectedchannelData, setselectedChannelListData] = useState(null);

  return (
    <AnnouncementChannelContext.Provider
      value={{ selectedchannelData, setselectedChannelListData }}
    >
      {children}
    </AnnouncementChannelContext.Provider>
  );
};
