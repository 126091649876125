// export function SecondaryBackButton() {
//   if (window.Telegram && window.Telegram.WebApp) {
//     const { WebApp } = window.Telegram;
//     // Initialize the WebApp
//     WebApp.ready();
//     // Make the back button visible
//     WebApp.BackButton.show();
//     // Set the callback function for the back button
//     WebApp.BackButton.onClick(() => {
//       // Define your back button functionality here
//       // For example, navigate to the previous page
//       console.log("Inside click",window.history);
//       window.history.back();
//     });

//     // Clean up function to hide the back button when the component is unmounted
//     return () => {
//       WebApp.BackButton.hide();
//     };
//   }
// }

let isInitialized = false;
let backButtonListener = null;
// onBack
export function BackButton(onBack) {
  if (window.Telegram && window.Telegram.WebApp) {
    const { WebApp } = window.Telegram;
    WebApp.ready();
    WebApp.BackButton.show();
    if (backButtonListener) {
      WebApp.BackButton.offClick(backButtonListener);
    }
    backButtonListener = () => {
      onBack();
      // window.history.back();
    };
    WebApp.BackButton.onClick(backButtonListener);
    isInitialized = true;
    return () => {
      console.log("Back button component unmounting");
      WebApp.BackButton.hide();
      WebApp.BackButton.offClick(backButtonListener);
      backButtonListener = null;
      isInitialized = false;
    };
  } else {
    console.error("Telegram WebApp is not available");
    return () => {};
  }
}

export function ExpandMiniAppToFullView() {
  if (window.Telegram && window.Telegram.WebApp) {
    const { WebApp } = window.Telegram;
    WebApp.ready();
    WebApp.expand();
  }
}

export function UserDetails() {
  if (window.Telegram.WebApp !== undefined) {
    const webApp = window.Telegram.WebApp;
    const user = webApp.initDataUnsafe?.user;
    return user;
  }
}

export function tgStart_Param() {
  if (window.Telegram.WebApp !== undefined) {
    const webApp = window.Telegram.WebApp;
    const startparam = webApp.initDataUnsafe?.start_param;
    return startparam;
  }
}
export function openTelegramLink(url) {
  const webApp = window.Telegram.WebApp;
  if (webApp && typeof webApp.openTelegramLink === "function") {
    // Try to open the link using the Web App  API
    try {
      webApp.openTelegramLink(url);
    } catch (error) {
      console.error("Unable to open the deep link using WebApp API:", error);
      window.open(url, "_blank");
    }
  } else {
    window.open(url, "_blank");
  }
}

export function ShareLink(url, text) {
  const sharingUrl = `https://t.me/share/url?url=${url}&text=${text}`;
  openTelegramLink(sharingUrl);
  return sharingUrl;
}

//Function to channge the theme based
export function initializeTelegramTheme() {
  const tg = window.Telegram.WebApp;
  const themeParams = tg.themeParams;

  // Function to apply theme based on Telegram theme parameters
  function applyTelegramTheme(themeParams) {
    if (themeParams.bg_color === "#ffffff") {
      document.body.classList.remove("dark-mode");
      localStorage.setItem("darkMode", "disabled");
    } else {
      document.body.classList.add("dark-mode");
      localStorage.setItem("darkMode", "enabled");
    }
  }

  // Apply initial theme settings
  applyTelegramTheme(themeParams);

  // Listen for theme changes from Telegram
  tg.onEvent("themeChanged", () => {
    const newThemeParams = tg.themeParams;
    applyTelegramTheme(newThemeParams);
  });

  // Cleanup function to remove event listeners
  return () => {
    tg.offEvent("themeChanged");
  };
}

//Used to show confirm popUp
export const showConfirm = (message, callback) => {
  // Show the confirmation dialog using the Telegram Mini App API
  window.Telegram.WebApp.showConfirm(message, (result) => {
    if (callback) {
      callback(result);
    }
  });
};
//Used to get the Platform device type for the mini app if the user open the mini app on mobile or any other devices type
export function GetTelegramDeviceType() {
  if (
    window.Telegram &&
    window.Telegram.WebApp &&
    window.Telegram.WebApp.platform
  ) {
    return window.Telegram.WebApp.platform;
  } else {
    console.error("Telegram WebApp API not available");
    return "Unknown device";
  }
}
export function addHomeOptionToTelegramMenu() {
  // Ensure the Telegram WebApp API is ready
  window.Telegram.WebApp.ready();

  // Add the "Go to Home" option to the three-dot menu
  window.Telegram.WebApp.setMenuButton({
    type: "menu", // Set the button type to 'menu'
    items: [
      {
        text: "Home", // The text that appears in the menu
        onClick: function () {
          // Action to perform when "Go to Home" is clicked
          window.location.href = "/"; // Redirect to the home page (adjust the path as needed)
        },
      },
    ],
  });
}

export function openInvoice(url, callback) {
  const webApp = window.Telegram.WebApp;
  if (webApp && typeof webApp.openInvoice === "function") {
    try {
      webApp.openInvoice(url, (status) => {
        console.log("Invoice closed with status:", status);
        if (callback) callback(status);
      });
    } catch (error) {
      console.error("Unable to open the Invoice URL using WebApp API:", error);
      window.open(url, "_blank");
    }
  } else {
    console.error("WebApp API is not available.");
    window.open(url, "_blank");
  }
}

// export function openLink(url, options = {}) {
//   const webApp = window.Telegram.WebApp;
//   if (webApp && typeof webApp.openLink === "function") {
//     // Validate that the URL is a string
//     if (typeof url !== "string") {
//       console.error("Invalid URL format.");
//       return;
//     }

//     // Set default options if not provided
//     const defaultOptions = { try_instant_view: false };
//     const finalOptions = { ...defaultOptions, ...options };

//     try {
//       // Call the WebApp API method
//       webApp.openLink(url, finalOptions);
//     } catch (error) {
//       console.error("Unable to open the link using WebApp API:", error);
//       window.open(url, "_blank"); // Fallback to opening in a new tab if the API fails
//     }
//   } else {
//     console.error("WebApp API is not available.");
//     window.open(url, "_blank"); // Fallback to opening in a new tab if WebApp API is unavailable
//   }
// }

export function openLink(url, options = {}) {
  const webApp = window.Telegram.WebApp;

  // Validate that the URL is a string
  if (typeof url !== "string") {
    console.error("Invalid URL format.");
    return;
  }

  // Set default options if not provided
  const defaultOptions = { try_instant_view: false };
  const finalOptions = { ...defaultOptions, ...options };

  try {
    // Detect if the user is on a mobile device
    const deviceType = GetTelegramDeviceType();

    if (webApp && typeof webApp.openLink === "function") {
      // On mobile devices, add a slight delay before opening the link
      if (deviceType.includes("ios")) {
        webApp.openLink(url, finalOptions);
        // setTimeout(() => {
        //   window.open(url, "_blank");
        // }, 500);
      } else if (!deviceType.includes("desktop")) {
        setTimeout(() => {
          window.open(url, "_blank"); // Force open in a new tab
        }, 300);
      } else {
        webApp.openLink(url, finalOptions);
      }
    } else {
      console.error("WebApp API is not available.");
      window.open(url, "_blank"); // Fallback to opening in a new tab if WebApp API is unavailable
    }
  } catch (error) {
    console.error("Unable to open the link using WebApp API:", error);
    window.open(url, "_blank"); // Fallback to opening in a new tab if the API fails
  }
}
