import React, { useEffect, useState } from "react";
import style from "../Css/Home.module.css";
import coin from "../../../assets/images/coin.png";
import gift from "../../../assets/images/gift.png";
import games from "../../../assets/images/game-controller.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { decrypt, encrypt } from "../../../Helper/EncryptionHelper";
import { usePrizeContext } from "../../../Context/PrizeContext";
import themeBg from "../../../assets/images/theme-bg-dark.png";
import { Get, Post } from "../../../Helper/ApiServices";
import { SplashScreen } from "../../SplashScreen/Components/SplashScreen";
import {
  ExpandMiniAppToFullView,
  tgStart_Param,
  UserDetails,
} from "../../../Helper/TgMiniAppFunctions";
import { decodeBase64 } from "../../../Helper/Decoder";
import { TaskStatus, UserStatusEnum } from "../../../Enums/Enum";
import {
  AddReferal,
  CaptureOrder,
  GetAnnouncementListToShowInMiniApp,
  GetCarouselTaskList,
  GetDrawerTaskList,
  GetUserStatusByUserId,
  UpdateCustomTaskStatus,
  UpdateIpAddressAndCountryCodeInUserDetails,
  UpdateIpAddressInUserDetails,
} from "../../../Helper/UrlConstant";
import { AnnouncementInMiniApp } from "./AnnouncementInMiniApp";
import { useCustomAlert } from "../../../Helper/Alerts";
//New slider into the Home
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper from "swiper";
// import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Payment } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroll-component";
var start_param = tgStart_Param();
export const Home = () => {
  // **********hook start************
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [AnnouncementListMiniApp, setAnnouncementListMiniApp] = useState([]);
  const [carouselData, setCarouselData] = useState([]);
  const [drawerData, setDrawerData] = useState([]);
  const { t } = useTranslation();
  const showAlert = useCustomAlert();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  //Const Declaration
  const user = UserDetails();
  //const carouselRef = useRef(null);
  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);
      ExpandMiniAppToFullView();
      await Promise.all([
        GetUserStatusByTgId(),
        fetchAnnouncementList(),
        UpdateIpofUser(),
        fetchShowInCarouselTasks(),
        fetchShowInDrawerTasks(),
      ]);
      setIsLoading(false);
    };
    initialize();
  }, []);

  useEffect(() => {
    const initialize = async () => {
      if (start_param) {
        try {
          // Wait for both API calls to complete before proceeding
          await Promise.all([GetUserStatusByTgId(), UpdateIpofUser()]);
        } catch (error) {
          console.error("Error in initial API calls:", error);
        }
        try {
          const decodedData = decodeBase64(start_param); // Decode from Base64
          const url = new URLSearchParams(decodedData);
          const taskId = url.get("taskId");
          //console.log("TaskID", taskId);
          const userId = url.get("UserId");
          const botId = url.get("BotId");
          const ReferBy = url.get("ReferBy");
          const referenceId = url.get("referenceId");
          const PaymentComplete = url.get("Pc");
          let decryptedPaymentComplete;
          if (PaymentComplete !== null && PaymentComplete !== undefined) {
            decryptedPaymentComplete = await decrypt(PaymentComplete);
          }
          if (botId !== undefined) {
            localStorage.setItem("BotId", botId);
          }
          if (
            referenceId !== null &&
            referenceId !== undefined &&
            referenceId !== ""
          ) {
            await fetchCaptureOrderStatus(referenceId);
          }

          if (
            decryptedPaymentComplete !== undefined &&
            decryptedPaymentComplete !== null &&
            decryptedPaymentComplete !== "" &&
            decryptedPaymentComplete === "True"
          ) {
            if (taskId !== "" && taskId !== undefined && taskId !== null) {
              await updateTaskStatus(taskId);
              navigate(`/AmountPending`, {
                state: { PaymentCompletedTaskId: taskId },
              });
              start_param = null;
              return;
            }
          }

          start_param = null;
          // if (ReferBy === null || ReferBy === undefined || ReferBy === "") {
          // Redirect to the task details page
          if (taskId !== "" && taskId !== undefined && taskId !== null) {
            navigate(
              `/TaskDetails/${encodeURIComponent(
                taskId
              )}?inviteduserId=${encodeURIComponent(userId)}`
            );
            return;
          }
          // } else if (ReferBy !== null && ReferBy !== undefined) {
          //   if (ReferBy !== String(user.id)) {
          //     await AddReferalInvition(ReferBy);
          //   }
          // }
        } catch (error) {
          console.error("Failed to decode task ID", error);
        } finally {
          // Ensure start_param is cleared even on failure
          start_param = null;
        }
      }
    };
    initialize();
  }, [start_param, navigate]);
  // Auto-run carousel every 3 seconds
  const loadMoreTasks = () => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  useEffect(() => {
    fetchShowInDrawerTasks(page);
  }, [page]);
  // ************hook ends*************

  // *******context comes here*****************
  const { prizes, setPrizes } = usePrizeContext();
  // *************context ends***************

  // ***************Carausal Code************

  //**************Caraousel code ends here */
  function onProfileClick(e) {
    // e.preventDefault();
    navigate("/SuperAdminProfile");
  }

  function onAmountClick() {
    navigate("/CreateTask");
  }

  function onGiftClick() {
    const newPrize = {
      id: prizes[prizes.length - 1].id + 1,
      PrizeType: "Gift",
      CurrencyType: "",
      Amount: 0,
      PrizeName: "",
      Quantity: 0,
      amountTouched: false,
      qtyTouched: false,
    };
    setPrizes([newPrize]);
    navigate("/CreateTask");
    // navigate(`/TaskDetails/12`);
  }

  //navigation functions and input handle functions
  function Navigation(path) {
    navigate(path);
  }
  const NavigateToTaskDetails = async (taskId) => {
    const encryptedTaskId = await encrypt(taskId);
    navigate(`/TaskDetails/${encodeURIComponent(encryptedTaskId)}`);
  };

  const navigateToCreateTask = () => {
    navigate("/CreateTask");
  };

  //Api functions
  const GetUserStatusByTgId = async () => {
    const param = {
      TGUserId:
        user != null && user != undefined ? await encrypt(String(user.id)) : "",
      BotId: await encrypt(localStorage.getItem("BotId")),
    };
    var response = await Get(GetUserStatusByUserId, param);
    if (response && response.statusCode === 200 && response.data) {
      // localStorage.setItem("IsAdmin", response.data.isAdmin);
      if (response.data.status === UserStatusEnum.Banned) {
        navigate("/UserRestricted");
      }
    } else if (response && response.statusCode !== 200) {
      showAlert("", t("Something Went Wrong"), "error");
    }
  };

  const fetchShowInCarouselTasks = async () => {
    var response = await Get(GetCarouselTaskList, {
      TGUserId:
        user != null && user != undefined ? await encrypt(String(user.id)) : "",
      BotId: await encrypt(localStorage.getItem("BotId")),
    });
    if (response && response.statusCode === 200 && response.data) {
      setCarouselData(response.data);
    } else if (response && response.statusCode !== 200) {
      showAlert("", t("Something Went Wrong"), "error");
    }
  };

  const fetchShowInDrawerTasks = async () => {
    try {
      var response = await Get(GetDrawerTaskList, {
        TGUserId:
          user != null && user != undefined
            ? await encrypt(String(user.id))
            : "",
        BotId: await encrypt(localStorage.getItem("BotId")),
        Page: page,
        PageSize: 10,
      });
      if (!response || !response.data) {
        setHasMore(false);
      } else if (response && response.statusCode === 200 && response.data) {
        if (response.data.length === 0) {
          setHasMore(false);
        } else {
          setDrawerData((prevTasks) => {
            // Check for duplicates before setting state
            const newTasks = response.data.filter(
              (task) => !prevTasks.some((prevTask) => prevTask.id === task.id)
            );
            return [...prevTasks, ...newTasks];
          });
          if (response.data.length < 10) {
            setHasMore(false);
          }
        }
        //setDrawerData(response.data);
      } else if (response && response.statusCode !== 200) {
        setHasMore(false);
        showAlert("", t("Something Went Wrong"), "error");
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error(error);
      setHasMore(false);
    }
  };

  const fetchCaptureOrderStatus = async (referenceId) => {
    const response = await Get(CaptureOrder, {
      ReferenceId: decodeURIComponent(referenceId),
      TGUserId:
        user != null && user != undefined ? await encrypt(String(user.id)) : "",
      BotId: await encrypt(localStorage.getItem("BotId")),
    });
    if (response && response.statusCode !== 200) {
      showAlert("", t("Something Went Wrong"), "error");
    }
  };

  const fetchAnnouncementList = async () => {
    const response = await Get(GetAnnouncementListToShowInMiniApp, {
      BotId: await encrypt(localStorage.getItem("BotId")),
      TGUserId:
        user != null && user != undefined ? await encrypt(String(user.id)) : "",
    });
    if (response && response.data) {
      setAnnouncementListMiniApp(response.data);
    } else if (response && response.statusCode !== 200) {
      showAlert("", t("Something Went Wrong"), "error");
    }
  };

  async function getUserIP() {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching user IP:", error);
      return null;
    }
  }

  const UpdateIpofUser = async () => {
    const Ip = await getUserIP();
    const response = await Post(UpdateIpAddressAndCountryCodeInUserDetails, {
      BotId: await encrypt(localStorage.getItem("BotId")),
      TGUserId:
        user != null && user != undefined ? await encrypt(String(user.id)) : "",
      IpAddress: Ip !== null && Ip !== undefined ? await encrypt(Ip) : "",
    });
    if (response && response.statusCode !== 200) {
      showAlert("", t("Something Went Wrong"), "error");
    }
  };

  const AddReferalInvition = async (ReferBy) => {
    const response = await Post(AddReferal, {
      InvitedBy: await encrypt(String(ReferBy)),
      InvitedUser:
        user != null && user != undefined ? await encrypt(String(user.id)) : "",
      BotId: await encrypt(localStorage.getItem("BotId")),
      TGUserId:
        user != null && user != undefined ? await encrypt(String(user.id)) : "",
    });
    if (response && response.statusCode !== 200) {
      showAlert("", t("Something Went Wrong"), "error");
    }
  };
  const updateTaskStatus = async (taskId) => {
    try {
      const response = await Post(UpdateCustomTaskStatus, {
        Id: decodeURIComponent(taskId),
        TGUserId:
          user != null && user != undefined
            ? await encrypt(String(user.id))
            : "",
        BotId: await encrypt(localStorage.getItem("BotId")),
        Status: await encrypt(TaskStatus.Paid),
      });
      if (response && response.statusCode !== 200) {
        showAlert("", t("Something Went Wrong"), "error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <div id="main-content" className="main-content">
          <div
            className="bg-image"
            style={{ backgroundImage: `url(${themeBg})` }}
          ></div>
          <div className="homepage">
            <div className="top-bar">
              <div className="container-fluid">
                <ul className="d-flex flex-wrap justify-content-between">
                  <li>{t("Telegram MiniApp")}</li>
                </ul>
              </div>
            </div>

            <div className="middle-offer-bar">
              <div className="container-fluid">
                <div className=" d-flex flex-wrap">
                  <div className="offer-box purple-bg " onClick={onAmountClick}>
                    <div className="text-top-box">
                      <h2>{t("Amount")}</h2>
                    </div>
                    <div className="img-bottom-box">
                      <img src={coin} alt="" />
                    </div>
                  </div>
                  <div className="offer-box blue-bg" onClick={onGiftClick}>
                    <div className="text-top-box">
                      <h2>{t("Gifts")}</h2>
                    </div>
                    <div className="img-bottom-box">
                      <img src={gift} alt="" />
                    </div>
                  </div>
                  <div
                    className="offer-box green-bg"
                    onClick={() => Navigation("/Userwallet")}
                  >
                    <div className="text-top-box">
                      <h2>{t("Games")}</h2>
                    </div>
                    <div className="img-bottom-box">
                      <img src={games} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {AnnouncementListMiniApp && AnnouncementListMiniApp.length > 0 && (
              <AnnouncementInMiniApp Announcement={AnnouncementListMiniApp} />
            )}

            <div className="offer-slider-sec">
              <div className="container-fluid">
                <div className="heading-listing">
                  {(carouselData.length > 0 || drawerData.length > 0) && (
                    <h3>{t("Populer Draws")}</h3>
                  )}
                </div>
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                  {carouselData &&
                    carouselData.map((task, index) => (
                      <SwiperSlide
                        key={index}
                        onClick={() => NavigateToTaskDetails(task.id)}
                      >
                        <img
                          style={{
                            height: "200px",
                            objectFit: "cover",
                          }}
                          src={task.customImage}
                          className="d-block w-100"
                          alt=""
                          loading="lazy"
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
            <InfiniteScroll
              dataLength={drawerData.length}
              next={loadMoreTasks}
              hasMore={hasMore}
              loader={
                <div
                  className="spinner-border"
                  style={{
                    width: "1rem",
                    height: "1rem",
                    color:
                      localStorage.getItem("darkMode") === "enabled"
                        ? "#b00009"
                        : "#86b7fe",
                  }}
                  role="status"
                ></div>
              }
              endMessage={""}
              style={{ overflow: "hidden" }}
            >
              <div className="game-listing">
                <div className="container-fluid">
                  <div className="gaming-common-list d-flex flex-wrap ">
                    {drawerData &&
                      drawerData.map((drawerTask) => (
                        <>
                          <div
                            key={drawerTask.id}
                            className="gaming-list"
                            onClick={() => NavigateToTaskDetails(drawerTask.id)}
                          >
                            <img
                              src={drawerTask.customImage}
                              style={{
                                height: "250px",
                                objectFit: "cover",
                              }}
                              alt=""
                              loading="lazy"
                            />
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </InfiniteScroll>
          </div>
          <div className="footer" style={{ zIndex: 2 }}>
            <div className="container-fluid">
              <div className="inner-footer">
                <div className="d-flex justify-content-between">
                  <div className="footer-box">
                    <a className="">
                      <svg width="25" height="25" viewBox="0 0 25 25">
                        <path d="M13.8801 2.11602C13.4324 1.73537 12.8638 1.52637 12.2761 1.52637C11.6884 1.52637 11.1199 1.73537 10.6721 2.11602L3.24261 8.43071C2.96914 8.66318 2.74948 8.95232 2.59883 9.27809C2.44818 9.60387 2.37014 9.9585 2.37012 10.3174V20.1929C2.37012 20.6982 2.57082 21.1827 2.92808 21.54C3.28534 21.8972 3.76988 22.0979 4.27512 22.0979H8.08512C8.59036 22.0979 9.0749 21.8972 9.43216 21.54C9.78941 21.1827 9.99012 20.6982 9.99012 20.1929V14.8589C9.99012 14.7579 10.0303 14.661 10.1017 14.5895C10.1732 14.5181 10.2701 14.4779 10.3711 14.4779H13.8001C13.9012 14.4779 13.9981 14.5181 14.0695 14.5895C14.141 14.661 14.1811 14.7579 14.1811 14.8589V20.1929C14.1811 20.6982 14.3818 21.1827 14.7391 21.54C15.0963 21.8972 15.5809 22.0979 16.0861 22.0979H20.2771C20.7824 22.0979 21.2669 21.8972 21.6242 21.54C21.9814 21.1827 22.1821 20.6982 22.1821 20.1929V10.3174C22.1821 9.9585 22.1041 9.60387 21.9534 9.27809C21.8028 8.95232 21.5831 8.66318 21.3096 8.43071L13.8801 2.11602Z" />
                      </svg>
                      <span>{t("Home")}</span>
                    </a>
                  </div>
                  <div className="footer-box">
                    <a className="" onClick={onProfileClick}>
                      <svg width="21" height="21" viewBox="0 0 21 21">
                        <path d="M10.8282 0.0319824C5.32048 0.0319824 0.668213 4.68425 0.668213 10.192C0.668213 15.6997 5.32048 20.352 10.8282 20.352C16.3359 20.352 20.9882 15.6997 20.9882 10.192C20.9882 4.68425 16.3359 0.0319824 10.8282 0.0319824ZM10.8282 5.11198C12.5828 5.11198 13.8762 6.40433 13.8762 8.15998C13.8762 9.91563 12.5828 11.208 10.8282 11.208C9.0746 11.208 7.78021 9.91563 7.78021 8.15998C7.78021 6.40433 9.0746 5.11198 10.8282 5.11198ZM5.64052 15.0403C6.55187 13.6992 8.07181 12.8051 9.81221 12.8051H11.8442C13.5856 12.8051 15.1046 13.6992 16.0159 15.0403C14.7175 16.4302 12.8755 17.304 10.8282 17.304C8.78097 17.304 6.93897 16.4302 5.64052 15.0403Z" />
                      </svg>
                      <span>{t("Profile")}</span>
                    </a>
                  </div>
                </div>
                <div className="add-upload">
                  <a className="btn-upload" onClick={navigateToCreateTask}>
                    <svg width="33" height="34" viewBox="0 0 33 34">
                      <path d="M19 3.25V14.5H30.25C30.9792 14.5 31.5781 14.7344 32.0469 15.2031C32.5156 15.6719 32.75 16.2708 32.75 17C32.75 17.7292 32.5156 18.3281 32.0469 18.7969C31.5781 19.2656 30.9792 19.5 30.25 19.5H19V30.75C19 31.4792 18.7656 32.0781 18.2969 32.5469C17.8281 33.0156 17.2292 33.25 16.5 33.25C15.7708 33.25 15.1719 33.0156 14.7031 32.5469C14.2344 32.0781 14 31.4792 14 30.75V19.5H2.75C2.02083 19.5 1.42188 19.2656 0.953125 18.7969C0.484375 18.3281 0.25 17.7292 0.25 17C0.25 16.2708 0.484375 15.6719 0.953125 15.2031C1.42188 14.7344 2.02083 14.5 2.75 14.5H14V3.25C14 2.52083 14.2344 1.92188 14.7031 1.45312C15.1719 0.984375 15.7708 0.75 16.5 0.75C17.2292 0.75 17.8281 0.984375 18.2969 1.45312C18.7656 1.92188 19 2.52083 19 3.25Z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
