import React, { createContext, useContext, useState } from "react";
const FacebookContext = createContext();

export const useFacebookContext = () => useContext(FacebookContext);

// Provider component to wrap your application
export const FacebookContextProvider = ({ children }) => {
  const [File, setFile] = useState();
  const [campaignName, setCampaignName] = useState();
  const [AdSetName, setAdSetName] = useState();
  const [AdCreativeName, setAdCreativeName] = useState();
  const [AdMessage, setAdMessage] = useState();
  const [DailyBudget, setDailyBudget] = useState();
  const [BidAmount, setBidAmount] = useState();
  const [AdRedirectURL, setAdRedirectURL] = useState();
  const [AdImageHash, setImageHash] = useState();
  const [AdCreativeId, setAdCreativeId] = useState();
  const [AdPreviewURL, setAdPreviewURL] = useState();
  const [taskId, setTaskId] = useState();
  const [selectedDuration, setSelectedDuration] = useState("1");
  return (
    <FacebookContext.Provider
      value={{
        File,
        setFile,
        campaignName,
        setCampaignName,
        AdSetName,
        setAdSetName,
        AdCreativeName,
        setAdCreativeName,
        AdMessage,
        setAdMessage,
        DailyBudget,
        setDailyBudget,
        BidAmount,
        setBidAmount,
        AdRedirectURL,
        setAdRedirectURL,
        AdImageHash,
        setImageHash,
        AdCreativeId,
        setAdCreativeId,
        AdPreviewURL,
        setAdPreviewURL,
        setTaskId,
        taskId,
        selectedDuration,
        setSelectedDuration,
      }}
    >
      {children}
    </FacebookContext.Provider>
  );
};
