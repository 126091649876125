import React, { createContext, useContext, useState } from 'react';
const ChannelContext = createContext();

export const useChannelContext = () => useContext(ChannelContext);

// Provider component to wrap your application
export const ChannelProvider = ({ children }) => {
    const [selectedchannelData, setselectedChannelListData] =  useState(null);
  
    return (
      <ChannelContext.Provider value={{selectedchannelData, setselectedChannelListData}}>
        {children}
      </ChannelContext.Provider>
    );
  };