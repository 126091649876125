import React, { createContext, useState } from "react";
import i18n from "../i18n";
import Cookies from "js-cookie";
export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const selectedlanguageValue = Cookies.get("i18next");
  const [language, setLanguage] = useState(i18n.language);
  const [selected, setSelected] = useState(selectedlanguageValue);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  return (
    <LanguageContext.Provider
      value={{ language, changeLanguage, selected, setSelected }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
