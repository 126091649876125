//Used in the CreatedTaskList Component ,Channel List , Group List component
export const GetTgUserByUserId = `${process.env.REACT_APP_APiBaseAddress}/api/UserDetails/GetTgUserByUserId`;
export const GetChannelList = `${process.env.REACT_APP_APiBaseAddress}/api/Channel/GetChannelList`;
export const GetCustomTaskListByUserId = `${process.env.REACT_APP_APiBaseAddress}/api/CustomTask/GetCustomTaskListByUserId`;
export const GetGroupList = `${process.env.REACT_APP_APiBaseAddress}/api/UserGroup/GetGroupList`;
export const GetCustomTaskByTaskId = `${process.env.REACT_APP_APiBaseAddress}/api/CustomTask/GetCustomTaskByTaskId`;
export const GetCustomTaskByTaskIdAndSendMessage = `${process.env.REACT_APP_APiBaseAddress}/api/CustomTask/GetCustomTaskByTaskIdAndSendMessage`;
export const GetCustomTaskStatus = `${process.env.REACT_APP_APiBaseAddress}/api/CustomTask/GetCustomTaskStatus`;

//Used into the Home component
export const GetUserStatusByUserId = `${process.env.REACT_APP_APiBaseAddress}/api/UserDetails/GetUserStatusByUserId`;
export const GetCarouselTaskList = `${process.env.REACT_APP_APiBaseAddress}/api/CustomTask/GetCarouselTaskList`;
export const GetDrawerTaskList = `${process.env.REACT_APP_APiBaseAddress}/api/CustomTask/GetDrawerTaskList`;
export const UpdateIpAddressAndCountryCodeInUserDetails = `${process.env.REACT_APP_APiBaseAddress}/api/UserDetails/UpdateIpAddressAndCountryCodeInUserDetails`;
export const AddReferal = `${process.env.REACT_APP_APiBaseAddress}/api/Referal/AddReferal`;
export const GetReferals = `${process.env.REACT_APP_APiBaseAddress}/api/Referal/GetReferals`;

//Used into the Join Channels
export const GetBotDetailByTgUserId = `${process.env.REACT_APP_APiBaseAddress}/api/BotDetails/GetBotDetailByTgUserId`;

//Used into the Manage User Component
export const GetUserListByUserId = `${process.env.REACT_APP_APiBaseAddress}/api/UserDetails/GetUserListByUserId`;
export const GetBannedUserList = `${process.env.REACT_APP_APiBaseAddress}/api/UserDetails/GetBannedUserList`;
export const UnbanUser = `${process.env.REACT_APP_APiBaseAddress}/api/UserDetails/UnbanUser`;

//Used into the UserDetails components
export const AddBanUser = `${process.env.REACT_APP_APiBaseAddress}/api/UserDetails/AddBanUser`;

//Used into the UserRestricted component
export const GetAdminUsernameByTGUserId = `${process.env.REACT_APP_APiBaseAddress}/api/UserDetails/GetAdminUsernameByTGUserId`;

//Used into the Task Details Component
export const GetLeaderboardbyTaskId = `${process.env.REACT_APP_APiBaseAddress}/api/CustomTaskParticipant/GetLeaderboardbyTaskId`;
export const GetChannelMemberByTgId = `${process.env.REACT_APP_APiBaseAddress}/api/Channel/GetChannelMemberByTgId`;
export const InviteLink = `${process.env.REACT_APP_APiBaseAddress}/api/BotHelper/CreateInviteLink`;
export const GetGroupMemberByUserId = `${process.env.REACT_APP_APiBaseAddress}/api/UserGroup/GetGroupMemberByUserId`;
export const CreateUserLinkVerification = `${process.env.REACT_APP_APiBaseAddress}/api/UserLink/CreateUserLinkVerification`;
export const GetUserLinkVerificationByConditionId = `${process.env.REACT_APP_APiBaseAddress}/api/UserLink/GetUserLinkVerificationByConditionId`;

//Used in the Enter component
export const CreateCustomTaskParticipant = `${process.env.REACT_APP_APiBaseAddress}/api/CustomTaskParticipant/CreateCustomTaskParticipant`;
export const GetCustomTaskParticipantByTaskId = `${process.env.REACT_APP_APiBaseAddress}/api/CustomTaskParticipant/GetCustomTaskParticipantByTaskId`;

//Used into the EditTask component
export const UpdateCustomTask = `${process.env.REACT_APP_APiBaseAddress}/api/CustomTask/UpdateCustomTask`;
export const UpdateCustomTaskStatus = `${process.env.REACT_APP_APiBaseAddress}/api/CustomTask/UpdateCustomTaskStatus`;

//Used into the Participants List
export const GetUserParticipantList = `${process.env.REACT_APP_APiBaseAddress}/api/CustomTask/GetUserParticipantList`;

//Used into the Announcement
export const AddAnnouncement = `${process.env.REACT_APP_APiBaseAddress}/api/Announcement/AddAnnouncement`;
export const GetAnnouncementList = `${process.env.REACT_APP_APiBaseAddress}/api/Announcement/GetAnnouncementList`;
export const GetAnnouncementListToShowInMiniApp = `${process.env.REACT_APP_APiBaseAddress}/api/Announcement/GetAnnouncementListToShowInMiniApp`;
export const DeleteAnnouncement = `${process.env.REACT_APP_APiBaseAddress}/api/Announcement/DeleteAnnouncement`;

//Used into the Profile Management
export const ChangeEmailAndUsername = `${process.env.REACT_APP_APiBaseAddress}/api/Account/ChangeEmailAndUsername`;

//Used into the Manageadmin Section
export const GetUsers = `${process.env.REACT_APP_APiBaseAddress}/api/User/GetUsers`;
export const DeleteUser = `${process.env.REACT_APP_APiBaseAddress}/api/User/DeleteUser`;
export const GetBotAdminByAdminId = `${process.env.REACT_APP_APiBaseAddress}/api/BotAdminDetails/GetBotAdminByAdminId`;

//Used into the Task details
export const GetCountryCodebyTgUserId = `${process.env.REACT_APP_APiBaseAddress}/api/UserDetails/GetCountryCodebyTgUserId`;

//Used in the webConfiguration
export const AddBotConfiguration = `${process.env.REACT_APP_APiBaseAddress}/api/ConfigurationSettings/AddBotConfiguration`;
export const GetBotConfiguration = `${process.env.REACT_APP_APiBaseAddress}/api/ConfigurationSettings/GetBotConfiguration`;

//Used into the Facebook ad
export const UploadImage = `${process.env.REACT_APP_APiBaseAddress}/api/FacebookAd/UploadImage`;
export const CreateAd = `${process.env.REACT_APP_APiBaseAddress}/api/FacebookAd/CreateAd`;
export const AdPreview = `${process.env.REACT_APP_APiBaseAddress}/api/FacebookAd/AdPreview`;
export const GetFacebookAdList = `${process.env.REACT_APP_APiBaseAddress}/api/FacebookAd/GetFacebookAdList`;
//Used into the taskDetails to Add the Winner
export const AddWinner = `${process.env.REACT_APP_APiBaseAddress}/api/Winner/AddWinner`;

//Used to Delete the CustomTask by Task Id
export const DeleteCustomTask = `${process.env.REACT_APP_APiBaseAddress}/api/CustomTask/DeleteCustomTask`;

//Used into the Winner for adding the Price Details
export const AddPriceReview = `${process.env.REACT_APP_APiBaseAddress}/api/PrizeReview/AddPriceReview`;

//Used for the Creating Order into the Payment Modal
export const CreateOrder = `${process.env.REACT_APP_APiBaseAddress}/api/Payment/CreateOrder`;
export const CreatePayment = `${process.env.REACT_APP_APiBaseAddress}/api/Payment/CreatePayment`;
export const GetHandlingPercAndOtherDetails = `${process.env.REACT_APP_APiBaseAddress}/api/Payment/GetHandlingPercAndOtherDetails`;
export const AddOrUpdatePaymentSettings = `${process.env.REACT_APP_APiBaseAddress}/api/Payment/AddOrUpdatePaymentSettings`;
export const GetPaymentStatus = `${process.env.REACT_APP_APiBaseAddress}/api/Payment/GetPaymentStatus`;
export const SendWithdrawl = `${process.env.REACT_APP_APiBaseAddress}/api/Payment/SendWithdrawl`;
export const GetWithDrawlList = `${process.env.REACT_APP_APiBaseAddress}/api/Payment/GetWithDrawlList`;
//Used into Payment Settings to get the list of the bots
export const GetBotList = `${process.env.REACT_APP_APiBaseAddress}/api/BotDetails/GetBotList`;

//Used to delete the bot details
export const DeleteBotDetail = `${process.env.REACT_APP_APiBaseAddress}/api/BotDetails/DeleteBotDetail`;

//Used into the PriceReview
export const GetPriceReviewList = `${process.env.REACT_APP_APiBaseAddress}/api/PrizeReview/GetPriceReviewList`;

//Used to get the Currencies list
export const GetCurrencies = `${process.env.REACT_APP_APiBaseAddress}/api/CustomTask/GetCurrencies`;

//Used for the UserWallet address
export const GetWalletAddress = `${process.env.REACT_APP_APiBaseAddress}/api/UserwalletAddress/GetWalletAddress`;
export const AddorUpdateWallet = `${process.env.REACT_APP_APiBaseAddress}/api/UserwalletAddress/AddorUpdateWallet`;

//Used to capture the status of the paypal payment
export const CaptureOrder = `${process.env.REACT_APP_APiBaseAddress}/api/PayPal/CaptureOrder`;
export const CreateAdOrder = `${process.env.REACT_APP_APiBaseAddress}/api/PayPal/CreateAdOrder`;

//Used to create new user into the UserDetails
export const CreateUser = `${process.env.REACT_APP_APiBaseAddress}/api/EnterTask/CreateUser`;
//Used to get the Default Image for the BotId
export const GetDefaultImagebyBotId = `${process.env.REACT_APP_APiBaseAddress}/api/ImageConfiguration/GetDefaultImagebyBotId`;

//Used to update the GroupMember details based on the chatmember
export const UpdateGroupMember = `${process.env.REACT_APP_APiBaseAddress}/api/UserGroup/UpdateGroupMember`;
//Used to update the ChannelMember detail based on the chatmember
export const UpdateChannelMemberDetails = `${process.env.REACT_APP_APiBaseAddress}/api/Channel/UpdateChannelMemberDetails`;
//Used to get the availabilty of the Wallet address
export const GetUserWalletAddressAvailable = `${process.env.REACT_APP_APiBaseAddress}/api/Winner/GetUserWalletAddressAvailable`;
//Used to get the winner detail
export const GetWinnerDetailsByTaskIdAndTGUserId = `${process.env.REACT_APP_APiBaseAddress}/api/Winner/GetWinnerDetailsByTaskIdAndTGUserId`;
//Used to get the claim the prize for the amount type
export const ClaimAmountPrize = `${process.env.REACT_APP_APiBaseAddress}/api/Payment/ClaimAmountPrize`;
//Used to get the Support Channel and Group details
export const GetDefaultSupportGroupByBotId = `${process.env.REACT_APP_APiBaseAddress}/api/UserGroup/GetDefaultSupportGroupByBotId`;
//Used to update the bot
export const UpdateBot = `${process.env.REACT_APP_APiBaseAddress}/api/BotDetails/UpdateBot`;
//Used to set the User to activcated User
export const SetActiveUser = `${process.env.REACT_APP_APiBaseAddress}/api/User/SetActiveUser`;
export const IsValidURL = `${process.env.REACT_APP_APiBaseAddress}/api/AccountPassword/IsValidURL`;

//Used for adding the Flag value for provifing the admin token
export const IsAdmin = true;
