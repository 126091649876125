import React, { useState } from "react";
import { useTranslation } from "react-i18next";
//New slider into the Home
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper from "swiper";
// import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
export const AnnouncementInMiniApp = ({ Announcement }) => {
  //hooks
  const [announcementcurrentIndex, setAnnouncementCurrentIndex] = useState(0);
  const { t } = useTranslation();
  return (
    <div>
      <div className="offer-slider-sec">
        <div className="container-fluid">
          <div className="heading-listing">
            <h3>{t("Announcement")}</h3>
          </div>
          <div
            id="announcementcarousel"
            className="carousel slide box-with-form"
          >
            {/* <div
              className="carousel-indicators"
              style={{
                background: "#44454f5c",
                width: "100%",
                margin: 0,
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              {Announcement &&
                Announcement.map((task, index) => (
                  <button
                    type="button"
                    key={index}
                    data-bs-target="#announcementcarousel"
                    data-bs-slide-to={index}
                    className={
                      announcementcurrentIndex === index ? "active" : ""
                    }
                    aria-current="true"
                    aria-label={`Slide ${index + 1}`}
                  ></button>
                ))}
            </div>
            <div
              className="carousel-inner  "
              style={{ borderRadius: "10px", background: "white" }}
            >
              {Announcement &&
                Announcement.map((task, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${
                      announcementcurrentIndex === index ? "active" : ""
                    }`}
                  >
                    <img
                      style={{
                        height: "200px",
                        objectFit: "cover",
                      }}
                      src={task.announcementImage}
                      className="d-block w-100  card-img-top"
                      alt={`Slide ${index + 1}`}
                    />
                    <div style={{ backgroundColor: "white" }}>
                      <p
                        className="card-text"
                        style={{ color: "black", height: "30vh" }}
                      >
                        {task.description}
                      </p>
                    </div>
                  </div>
                ))}
            </div> */}
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Navigation]}
              className="mySwiper"
            >
              {Announcement &&
                Announcement.map((task, index) => (
                  <SwiperSlide
                    key={index}
                    // className={`carousel-item ${
                    //   announcementcurrentIndex === index ? "active" : ""
                    // }`}
                  >
                    <img
                      style={{
                        height: "200px",
                        objectFit: "cover",
                      }}
                      src={task.announcementImage}
                      className="d-block w-100  card-img-top"
                      // alt={`Slide ${index + 1}`}
                      alt=""
                      loading="lazy"
                    />
                    <div>
                      <p
                        className="card-text"
                        // style={{ color: "black", height: "30vh" }}
                      >
                        {task.description}
                      </p>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};
