import React, { createContext, useContext, useState } from "react";
const SpecificRegionContext = createContext();

export const useSpecificRegionContext = () => useContext(SpecificRegionContext);

// Provider component to wrap your application
export const SpecificRegionProvider = ({ children }) => {
  const [checkedCountries, setCheckedCountries] = useState([]);
  const [isRegionVerified, setIsRegionVerified] = useState(false);
  return (
    <SpecificRegionContext.Provider
      value={{
        checkedCountries,
        setCheckedCountries,
        isRegionVerified,
        setIsRegionVerified,
      }}
    >
      {children}
    </SpecificRegionContext.Provider>
  );
};
