import React, { createContext, useContext, useState } from 'react';
const GroupContext = createContext();

export const useGroupContext = () => useContext(GroupContext);

// Provider component to wrap your application
export const GroupProvider = ({ children }) => {
    const [selectedGroupData,setSelectedgroupData] =  useState(null);
  
    return (
      <GroupContext.Provider value={{selectedGroupData,setSelectedgroupData}}>
        {children}
      </GroupContext.Provider>
    );
  };