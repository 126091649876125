import React, { createContext, useState } from "react";

const SignInContext = createContext();

const SignInProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    usernameOrEmail: "",
    password: "",
    rememberMe: false,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const value = {
    formData,
    setFormData,
    errors,
    setErrors,
    handleChange,
  };

  return (
    <SignInContext.Provider value={value}>{children}</SignInContext.Provider>
  );
};

export { SignInContext, SignInProvider };
