import React from "react";
import { useTranslation } from "react-i18next";
function DrawRules() {
  const { t } = useTranslation();
  // Define draw rules directly within the component
  const drawRules = [
    {
      id: 1,
      title: t("Draw Rules"),
      rules: [
        t(
          "Starting with the first prize, randomly draw the winners for each award."
        ),
        t("Each participant is limited to winning only once."),
        t(
          "After activating the invitation feature, inviting others to participate can increase the chances of winning."
        ),
      ],
    },
  ];
  return (
    <>
      <div>
        {drawRules.map((rule) => (
          <div className="form-group input-single" key={rule.id}>
            <div className="accordion-box-leader">
              <a
                className="btn btn-border-with select"
                data-bs-toggle="collapse"
                href="#DrawRule"
                role="button"
                aria-expanded="true"
                aria-controls="collapseExample"
              >
                {rule.title}
              </a>
              <div className="collapse show" id="DrawRule">
                <div className="leaderboard-section ">
                  <div className="inner-leaderboard">
                    <div className="top-leaderboard">
                      <h3>
                        {rule.rules.map((item, index) => (
                          <p key={index}>
                            {index + 1} : {item}
                          </p>
                        ))}
                      </h3>
                    </div>
                    <div className="leaderboard-body"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default DrawRules;
