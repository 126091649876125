export const PrizeTypeEnum = {
  Gift: 0,
  Amount: 1,
};

export const CurrencyTypeEnum = {
  USDT: 0,
  Yuan: 1,
  // Add other currencies as needed
};

export const UserStatusEnum = {
  Active: 0,
  Inactive: 1,
  Banned: 2,
};

export const IsDrawn = {
  Awaiting: 0,
  Drawn: 1,
};

export const CurrencyDignEnum = {
  $: 0,
  "¥": 1,

  // Add other currencies as needed
};

export const TaskStatus = {
  Pending: 0,
  Paid: 1,
  Expired: 2,
  Drawn: 3,
  Awaiting: 4,
};
export const PriceReviewStatus = {
  Pending: 0,
  Passed: 1,
  Rejected: 2,
};
export const FacebookAdStatus = {
  Unpaid: 0,
  Paid: 1,
  Published: 2,
};
// Function to get the status name
export const StatusDesc = (status) => {
  switch (status) {
    case UserStatusEnum.Active:
      return "Active";
    case UserStatusEnum.Inactive:
      return "Inactive";
    case UserStatusEnum.Banned:
      return "Banned";
    default:
      return "Not Available";
  }
};

export const TaskStatusDesc = (status) => {
  switch (status) {
    case TaskStatus.Pending:
      return "Pending";
    case TaskStatus.Paid:
      return "Paid";
    case TaskStatus.Unpaid:
      return "Unpaid";
    case TaskStatus.Drawn:
      return "Drawn";
    case TaskStatus.Awaiting:
      return "Awaiting";
    default:
      return "Not Available";
  }
};
export const PriceReviewStatusDesc = (status) => {
  switch (status) {
    case PriceReviewStatus.Pending:
      return "Pending";
    case PriceReviewStatus.Passed:
      return "Passed";
    case PriceReviewStatus.Rejected:
      return "Rejected";
    default:
      return "Not Available";
  }
};

export const FacebookAdStatusDesc = (status) => {
  switch (status) {
    case FacebookAdStatus.Unpaid:
      return "Unpaid";
    case FacebookAdStatus.Paid:
      return "Paid";
    case FacebookAdStatus.Published:
      return "Published";
    default:
      return "Not Available";
  }
};
