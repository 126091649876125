import React, { createContext, useContext, useState } from "react";
// Create context
const PrizeContext = createContext();

// Custom hook to access prize data and update functions
export const usePrizeContext = () => useContext(PrizeContext);

// Provider component to wrap your application
export const PrizeProvider = ({ children }) => {
  const [prizes, setPrizes] = useState([
    {
      id: 1,
      PrizeType: "Amount",
      CurrencyType: "",
      Amount: 0,
      PrizeName: "",
      Quantity: 0,
      amountTouched: false,
      qtyTouched: false,
      prizeNameTouched: false,
    },
  ]);
  const [touchedAmount, setTouchedAmount] = useState(false);

  const [touchedQty, setTouchedQty] = useState(false);
  const [touchedCurrency, setTouchedCurrency] = useState(false);

  const [touchedPrizeName, setTouchedPrizeName] = useState(false);
  return (
    <PrizeContext.Provider
      value={{
        prizes,
        setPrizes,
        touchedAmount,
        setTouchedAmount,
        touchedQty,
        setTouchedQty,
        touchedPrizeName,
        setTouchedPrizeName,
        touchedCurrency,
        setTouchedCurrency,
      }}
    >
      {children}
    </PrizeContext.Provider>
  );
};
