import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./i18n"; // Import the i18n configuration
import { LanguageProvider } from "./Context/LanguageContext";
import { positions, Provider, transitions } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import "bootstrap-icons/font/bootstrap-icons.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
const options = {
  timeout: 2000,
  position: positions.MIDDLE,
  transition: transitions.FADE,
  containerStyle: {
    borderRadius: "50%", // Example of borderRadius style
    fontSize: "16px", // Example font size
    color: "#ffffff",
    padding: "10px",
    fontFamily: '"Roboto", sans-serif',
    textAlign: "center", // Text color
  },
};

ReactDOM.render(
  // <React.StrictMode>
  <LanguageProvider>
    <Provider template={AlertTemplate} {...options}>
      <App />
    </Provider>
  </LanguageProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);
