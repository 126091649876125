import React, { useEffect, useState } from "react";
import {
  UserDetails,
  ShareLink,
  GetTelegramDeviceType,
} from "../../../Helper/TgMiniAppFunctions";
import { encodeBase64 } from "../../../Helper/Decoder";
import { FaShareAlt } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { BsThreeDots } from "react-icons/bs";
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  XIcon,
} from "react-share";
import { decrypt, encrypt } from "../../../Helper/EncryptionHelper";
export const SharingLinkPopUp = ({
  show,
  setShow,
  taskid,
  sendmessage,
  botDetail,
  task,
  ReferalUrl,
}) => {
  //   //hooks
  const [showAlert, setShowAlert] = useState(false);
  const [decryptedUserId, setDecryptedUserId] = useState(null);
  const [encryptedUserId, setEncryptedUserId] = useState(null);
  const [encryptedEmptyId, setEncryptedEmptyId] = useState(null);
  const { t } = useTranslation();
  const deviceType = GetTelegramDeviceType();
  //   //hooks ends here

  //   //const Declaration
  const currentUserDetails = UserDetails();
  useEffect(() => {
    const fetchDecryptedUserId = async () => {
      if (task?.createByUserId) {
        try {
          const decryptedId = await decrypt(task.createByUserId); // Assuming decrypt returns the user ID
          setDecryptedUserId(decryptedId);
        } catch (error) {
          console.error("Decryption failed:", error);
        }
      }
    };

    fetchDecryptedUserId();
  }, [task?.createByUserId]); // Runs when createByUserId changes
  useEffect(() => {
    const encryptUserId = async () => {
      if (currentUserDetails?.id) {
        try {
          const encryptedId = await encrypt(currentUserDetails?.id);
          const encryptedEmptyId = await encrypt("");
          setEncryptedUserId(encryptedId);
          setEncryptedEmptyId(encryptedEmptyId);
        } catch (error) {
          console.error("Encryption failed:", error);
        }
      }
    };

    encryptUserId();
  }, [currentUserDetails?.id]);
  const url = `${botDetail}?startapp=${encodeBase64(
    `taskId=${encodeURIComponent(taskid)}&UserId=${
      String(decryptedUserId) === String(currentUserDetails?.id)
        ? encodeURIComponent(encryptedEmptyId)
        : encodeURIComponent(encryptedUserId)
    }&BotId=${localStorage.getItem("BotId")}&ReferBy=`
  )}`;

  //Api function
  const messageToSocialMedia = sendmessage
    .replace(/\n/g, "\n") // Ensure line breaks are used
    .replace(/ +/g, " ") // Replace multiple spaces with a single space
    .replace(/\n/g, "%0A");
  //   //handle functions
  const handleSharingLink = () => {
    const textmessage = sendmessage
      .replace(/\n/g, "\n") // Ensure line breaks are used
      .replace(/ +/g, " ") // Replace multiple spaces with a single space
      .replace(/\n/g, "%0A"); // Use URL encoding for newlines

    ShareLink(
      ReferalUrl !== null && ReferalUrl !== undefined ? "" : url,
      textmessage
    );
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      ReferalUrl !== null && ReferalUrl !== undefined ? ReferalUrl : url
    );
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 2000); // Hide the alert after 2 seconds
  };

  const handleNavigationShare = async () => {
    if (navigator.share) {
      // Web Share API is supported

      try {
        await navigator.share({
          text: sendmessage,
          url:
            ReferalUrl !== null && ReferalUrl !== undefined ? ReferalUrl : url,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      console.log("outside the Navigation");

      // Web Share API is not supported, show a custom share dialog
      // const shareDialog = document.getElementById("shareDialog");
      // shareDialog.style.display = "block";
    }
  };
  const formatMessage = (message) => {
    return message.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };
  return (
    <div>
      <div>
        <div
          className="modal fade send-modal"
          id="SharingLinkPopup"
          tabindex="-1"
          aria-labelledby="exampleModalLabelSend"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header d-flex align-items-center justify-content-between">
                <p className="d-flex align-items-center">
                  {t("Invite Friends to the Task")}
                </p>
              </div>
              <div className="modal-body">
                <div className="content-modal">
                  <p>{formatMessage(sendmessage)}</p>

                  {showAlert && (
                    <div className="alert alert-success" role="alert">
                      {t("URL copied to clipboard successfully")}
                    </div>
                  )}

                  <div className="row mt-3 mb-3">
                    <div className="col-2" style={{ width: "70px" }}>
                      <span className="d-flex justify-content-center">
                        <button
                          className="btn btn-primary  d-flex align-items-center justify-content-center"
                          onClick={handleSharingLink}
                        >
                          <FaShareAlt className="mx-2" />
                          {/* {t("Share")} */}
                        </button>
                      </span>
                      <span className="d-flex justify-content-center mt-2">
                        {t("Chat")}
                      </span>
                    </div>
                    <div className="col-2" style={{ width: "70px" }}>
                      <span className="d-flex justify-content-center">
                        <button
                          href=""
                          className="btn btn-primary  d-flex align-items-center justify-content-center"
                          onClick={handleCopyLink}
                        >
                          <MdContentCopy className="mx-2" />
                          {/* {t("Copy Url")} */}
                        </button>
                      </span>
                      <span
                        style={{ width: "max-content" }}
                        className="d-flex justify-content-center mt-2"
                      >
                        {t("Copy Link")}
                      </span>
                    </div>
                    <div className="col-2" style={{ width: "70px" }}>
                      <span className="d-flex justify-content-center">
                        <TwitterShareButton
                          url={
                            ReferalUrl !== null && ReferalUrl !== undefined
                              ? ReferalUrl
                              : url
                          }
                          title={messageToSocialMedia}
                          separator="\n"
                        >
                          <XIcon size={42} round />
                        </TwitterShareButton>
                      </span>
                      <span className="d-flex justify-content-center mt-2">
                        {t("X")}
                      </span>
                    </div>
                    <div className="col-2" style={{ width: "70px" }}>
                      <span className="d-flex justify-content-center">
                        <WhatsappShareButton
                          url={
                            ReferalUrl !== null && ReferalUrl !== undefined
                              ? " "
                              : url
                          }
                          title={messageToSocialMedia}
                          // separator="\n"
                        >
                          <WhatsappIcon size={42} round />
                        </WhatsappShareButton>
                      </span>
                      <span className="d-flex justify-content-center mt-2">
                        {t("WhatsApp")}
                      </span>
                    </div>
                    <div className="col-2" style={{ width: "70px" }}>
                      <span className="d-flex justify-content-center">
                        <FacebookShareButton
                          url={
                            ReferalUrl !== null && ReferalUrl !== undefined
                              ? ReferalUrl
                              : url
                          }
                          quote={messageToSocialMedia}
                        >
                          <FacebookIcon size={42} round />
                        </FacebookShareButton>
                      </span>
                      <span className="d-flex justify-content-center mt-2">
                        {t("Facebook")}
                      </span>
                    </div>
                    {deviceType && !deviceType.includes("desktop") && (
                      <div className="col-2" style={{ width: "70px" }}>
                        <span className="d-flex justify-content-center">
                          <button
                            className="btn btn-primary  d-flex align-items-center justify-content-center"
                            onClick={() => handleNavigationShare()}
                          >
                            <BsThreeDots className="mx-2" />
                            {/* {t("Copy Url")} */}
                          </button>
                        </span>
                        <span className="d-flex justify-content-center mt-2">
                          {t("More")}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
