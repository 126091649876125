import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { decodeJwt } from "./Helper/Decoder";

const PrivateRoute = ({ allowedRoles }) => {
  const navigate = useNavigate();
  const token = Cookies.get("qEWbpONT");

  let userRole = null;
  if (token) {
    const decodedToken = decodeJwt(token);
    userRole =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
  }

  React.useEffect(() => {
    if (!allowedRoles.includes(userRole)) {
      navigate("/SignIn", { replace: true });
    }
  }, [allowedRoles, userRole, navigate]);

  return allowedRoles.includes(userRole) ? <Outlet /> : null; // Render nothing while redirecting
};

export default PrivateRoute;
