import React, { createContext, useState, useContext, useEffect } from "react";
import { Get } from "../Helper/ApiServices";
import { encrypt } from "../Helper/EncryptionHelper";
import { GetDefaultImagebyBotId } from "../Helper/UrlConstant";

const ImageContext = createContext();

export function useImageContext() {
  return useContext(ImageContext);
}

export function ImageProvider({ children }) {
  const [imageUrl, setImageUrl] = useState(
    "https://img.freepik.com/free-vector/realistic-shopping-banner-design_23-2150277680.jpg?w=826&t=st=1716544980~exp=1716545580~hmac=59d340918106b4421c0d77bac090214cd4f42720f88d1dced2b540ff533b1f21" // default image
  );
  const [previewUrl, setPreviewUrl] = useState(null);
  const [announcementImageUrl, setAnnouncementImageUrl] = useState(null);
  const [announcementpreviewUrl, setAnnouncementPreviewUrl] = useState(null);

  useEffect(() => {
    const fetchDefaultImage = async () => {
      try {
        const botId = localStorage.getItem("BotId");
        if (botId) {
          const response = await Get(GetDefaultImagebyBotId, {
            BotId: await encrypt(botId),
          });
          if (response && response.data) {
            const fetchedImage = response.data;
            localStorage.setItem("DefaultImage", fetchedImage); // Save in localStorage
            setImageUrl(fetchedImage); // Set the state with fetched image
          } else {
            // Handle error response here
            console.error("Something Went Wrong");
          }
        }
      } catch (error) {
        console.error("Error fetching the default image:", error);
      }
    };
    fetchDefaultImage();
    // Check if image exists in localStorage
    // const storedImage = localStorage.getItem("DefaultImage");
    // if (storedImage !== null && storedImage !== undefined) {
    //   setImageUrl(storedImage); // Set from localStorage if exists
    // } else {
    //   fetchDefaultImage(); // Fetch from API if not in localStorage
    // }
  }, []);

  const handleImageUpload = (base64String) => {
    setImageUrl(base64String);
  };
  const handleAnnouncementImageUpload = (base64String) => {
    setAnnouncementImageUrl(base64String);
  };
  return (
    <ImageContext.Provider
      value={{
        imageUrl,
        setImageUrl,
        handleImageUpload,
        previewUrl,
        setPreviewUrl,
        announcementImageUrl,
        setAnnouncementImageUrl,
        announcementpreviewUrl,
        setAnnouncementPreviewUrl,
        handleAnnouncementImageUpload,
      }}
    >
      {children}
    </ImageContext.Provider>
  );
}
