import React from "react";
import { useImageContext } from "../../../Context/ImageContext";
import { useTranslation } from "react-i18next";

const ImageUploadComponent = ({ IsforAnnouncement }) => {
  const {
    handleImageUpload,
    previewUrl,
    setPreviewUrl,
    announcementpreviewUrl,
    setAnnouncementPreviewUrl,
    handleAnnouncementImageUpload,
  } = useImageContext();
  const { t } = useTranslation();

  const handleChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    if (!file.type.startsWith("image/")) {
      alert(t("Please select an image file."));
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result;
      if (IsforAnnouncement) {
        setAnnouncementPreviewUrl(base64String);
        handleAnnouncementImageUpload(base64String);
      } else {
        setPreviewUrl(base64String);
        handleImageUpload(base64String);
      }
    };

    reader.readAsDataURL(file);
  };

  const onCancelClick = () => {
    setPreviewUrl(null);
    setAnnouncementPreviewUrl(null);
    document.getElementById("imgInp").value = null;
  };

  return (
    <>
      <button className="close-button btn btn-primary" onClick={onCancelClick}>
        <span className="close-icon">&times;</span>
      </button>
      <div className="form-group input-single upload-file">
        <label className="d-none">{t("Upload Image")}</label>

        <div className="input-group">
          <span className="input-group-btn">
            <span className="btn btn-default btn-file">
              <svg
                width="53"
                height="53"
                viewBox="0 0 53 53"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="26.5"
                  cy="26.5"
                  r="26.5"
                  fill="var(--primary-color)"
                />
                <path
                  d="M16 26.5C16 27.1236 16.5218 27.6327 17.1327 27.6327H25.3673V35.8673C25.3673 36.4782 25.8764 37 26.5 37C27.1236 37 27.6455 36.4782 27.6455 35.8673V27.6327H35.8673C36.4782 27.6327 37 27.1236 37 26.5C37 25.8764 36.4782 25.3545 35.8673 25.3545H27.6455V17.1327C27.6455 16.5218 27.1236 16 26.5 16C25.8764 16 25.3673 16.5218 25.3673 17.1327V25.3545H17.1327C16.5218 25.3545 16 25.8764 16 26.5Z"
                  fill="#ffffff"
                />
              </svg>
              {IsforAnnouncement
                ? t("Choose Announcement Image")
                : t("Choose Draw Image")}

              <input
                type="file"
                id="imgInp"
                onChange={handleChange}
                accept="image/jpeg, image/png"
              />
            </span>
          </span>
          {IsforAnnouncement ? (
            <input
              type="text"
              name="img"
              className="form-control"
              value={announcementpreviewUrl || ""}
              readOnly
            />
          ) : (
            <input
              type="text"
              name="img"
              className="form-control"
              value={previewUrl || ""}
              readOnly
            />
          )}
        </div>
        {IsforAnnouncement
          ? announcementpreviewUrl && (
              <img id="img-upload" src={announcementpreviewUrl} alt="Preview" />
            )
          : previewUrl && (
              <img id="img-upload" src={previewUrl} alt="Preview" />
            )}
      </div>
    </>
  );
};

export default ImageUploadComponent;
