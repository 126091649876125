import React, { createContext, useContext, useState } from "react";

const ChannelListCardContext = createContext();

export const ChannelListCardProvider = ({ children }) => {
  const [channels, setChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);

  const handleChannelSelection = (channelId, channelName, channeltgId) => {
    const index = selectedChannels.findIndex(
      (channel) => channel.id === channelId
    );
    if (index === -1) {
      setSelectedChannels((prevSelectedChannels) => [
        ...prevSelectedChannels,
        { id: channelId, name: channelName, channelId: channeltgId },
      ]);
    } else {
      setSelectedChannels((prevSelectedChannels) =>
        prevSelectedChannels.filter((channel) => channel.id !== channelId)
      );
    }
  };

  return (
    <ChannelListCardContext.Provider
      value={{
        channels,
        setChannels,
        selectedChannels,
        handleChannelSelection,
        setSelectedChannels,
      }}
    >
      {children}
    </ChannelListCardContext.Provider>
  );
};

export const useChannelListCardContext = () =>
  useContext(ChannelListCardContext);
